/*************************************************************************************************/
/* Pagination ************************************************************************************/
/*************************************************************************************************/

.vgc-archive {

  padding-bottom: $spacing_md;

  select {

    width: 100%;    

    border: 2px solid $vgc-lightgrey;
    outline: none !important;
    @include brand-font('400');
    background: #f5f5f5;
    color: $vgc-black;
    padding: 5px 10px;
  
  }

  .calendar_wrap {

    width: 100%;    

    border: 2px solid $vgc-lightgrey;
    border-top: none;
    @include brand-font('400');
    background: #f5f5f5;
    color: $vgc-black;    

    table {
      width: 100%;
      padding: 5px 9px;

      th, td {
        text-align: center;
      }
      th {
        background: rgba(0,0,0,0.1);
      }
      td {
        color: $vgc-grey;
      }
      caption {
        display: none !important;
      }
    }    

    .wp-calendar-nav {
      background: rgba(0,0,0,0.1);
      padding: 5px 10px;      
      .wp-calendar-nav-next {
        float: right;
      }
    }

  }

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {



}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {



}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {



}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/