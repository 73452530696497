// FOr things that are re-used across components

@mixin scoreblock( $size: "sm" ) {

    display: flex;
    justify-content: center; 
    align-items: center;
    padding: $spacing_sm;
    margin-bottom: $spacing_sm;
    @include background-gradient("90deg", rgba($vgc-purple, 0.95), rgba($vgc-plum, 0.95)); 
    @include corner-bit();

    svg {
      width: 24px;
      height: 24px;
      margin: 0 $spacing_sm;

      path {
        fill: $vgc-white;
      }
    }

    &.score-1 {
      svg:nth-child(n+2) {
        opacity: .33;

        path {
          fill: $vgc-grey;
        }
      }
    }
    &.score-2 {
      svg:nth-child(n+3) {
        opacity: .33;
        
        path {
          fill: $vgc-grey;
        }
      }
    }
    &.score-3 {
      svg:nth-child(n+4) {
        opacity: .33;
        
        path {
          fill: $vgc-grey;
        }
      }
    }
    &.score-4 {
      svg:nth-child(n+5) {
        opacity: .33;
        
        path {
          fill: $vgc-grey;
        }
      }
    }
    &.score-5 {
      svg:nth-child(n+6) {
        opacity: .33;
        
        path {
          fill: $vgc-grey;
        }
      }
    }

}