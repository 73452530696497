/*************************************************************************************************/
/** Update Shortcode *****************************************************************************/
/*************************************************************************************************/

.updatecontainer {
  border-top: 1px solid rgba($vgc-lightgrey, 0.5);
  border-bottom: 1px solid rgba($vgc-lightgrey, 0.5);

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  clear: both;
  position: relative;

  &::before {
    display: block;

    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    padding: 5px 15px;
    background: #fff;

    content: "Update";

    color: $vgc-lightgrey;
    text-transform: uppercase;
    font-size: 50%;
    font-weight: bold;
  }
  &::after {
    display: block;

    position: absolute;
    bottom: -13px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    padding: 5px 15px;
    background: #fff;

    content: "End of Update";

    color: $vgc-lightgrey;
    text-transform: uppercase;
    font-size: 50%;
    font-weight: bold;
  }

}

.updatecontainer + .updatecontainer {
  margin-top: -1.5rem;
  border-top: none;
  &::before {    
    content: "Further update";
  }
  &::after {
    content: "End of updates";
  }
}

.vgc-update {

  background: #f5f5f5;
  padding: $spacing_sm;  

  margin-left: -10px;
  margin-right: -10px;

  p, li {
    // style 'em a special way?
  }

  ul:last-child,
  p:last-child {
    margin-bottom: 0;
  }

  .vgc-update__title {
    font-size: 1rem !important;    
    padding-bottom: 6px !important;
    text-transform: uppercase;
    position: relative;    
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(90deg,#f47820,#feb823);
    }

    svg {
      width: 19px;
      height: 19px;
      margin-right: 6px;
      fill: #999;
      position: relative;
      top: -2px;
    }

    span {
      color: #999;
    }
    time {
      float: right;
      color: #999;
      font-size: 0.8em;
    }
  }

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .vgc-update {    
    margin-left: 0;
    margin-right: 0;
  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {

 

}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/