/*************************************************************************************************/
/* Products **************************************************************************************/
/*************************************************************************************************/

.vgc-productsblock {
  //background: #f5f5f5;

  border-top: 1px solid $vgc-lightgrey;
  padding-top: $spacing_md;
  margin-top: $spacing_md;

  .vgc-productslist {

    > h1, > h2, > h3, > h4, > h5, > h6 {
      display: none;
    }

    .vgc-product {
//      background: rgba(255,0,255,0.1);
      margin-bottom: $spacing_md;
    }
  }  

  // Limit to 2 per list max at mobile
  &--games {
    .vgc-productslist {
      .vgc-product:nth-child(n+3) {
        display: none;
      }
    }
  }
  &--posts {
    .vgc-productslist {
      .vgc-product:nth-child(n+3) {
        display: none;
      }
    }
  }

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {



}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {
 
}

@include media-breakpoint-only(lg) { 
 
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {

  .vgc-productsblock {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing_md;
    
    .vgc-productslist {
      flex: 1;
      margin-bottom: 0;
      .vgc-product {
        margin-bottom: $spacing_lg;
      }
    }
    .affiliate-notice {
      flex-basis: 100%;
      margin: 0;
      padding: 0;
    }

    // Show the full lists again, since we're now in 2 colums
    &--games,
    &--posts {
      .vgc-productslist {
        .vgc-product {
          display: flex !important;
        }
      }
    }
  }  
  
}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/