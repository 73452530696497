/*************************************************************************************************/
/** Boxout Shortcode *****************************************************************************/
/*************************************************************************************************/

.vgc-boxout {

  background: #f5f5f5;
  padding: $spacing_sm;
  margin-bottom: 1rem;

  font-size: 0.8em;

  margin-left: -10px;
  margin-right: -10px;

  p, li {
    // style 'em a special way?
  }

  ul:last-child,
  p:last-child {
    margin-bottom: 0;
  }

  .vgc-boxout__title {
    font-size: 1rem !important;    
    padding-bottom: 6px !important;
    text-transform: uppercase;
    position: relative;    
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(90deg,#f47820,#feb823);
    }
  }

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .vgc-boxout {
    float: right;
    clear: both;

    margin-bottom: 1rem;
    margin-left: 1rem;

    margin-top: 0;
    margin-right: 0;

    max-width: 50%;
    width: 50%;

  }  

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {

/*
  .vgc-boxout {
    
    position: relative;
    z-index: 999;

//    padding: 0;    
    float: left;
    margin-left: -260px;
    width: 230px;

//    background: none;
//    padding-top: 2px;

    .vgc-boxout__title {
//      font-size: .75rem !important;
//      margin-top: -5px;
    }

  }
*/  

}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/