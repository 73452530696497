/*************************************************************************************************/
/** Embeds ***************************************************************************************/
/*************************************************************************************************/

.vgc-embed,
.commercial-slot-1,
.commercial-slot-2,
.commercial-slot-3 {
  margin-top: 2rem;
  margin-bottom: 2rem;

  // background: $bytes-grey; // interferes with Twitter embeds...

  iframe {
    max-width: 100vw !important;
  }

  // Placeholder loading style for Tweets that isn't shit
  blockquote.twitter-tweet {
    background: $bytes-grey;
    line-height: 100%;
    font-size: 12px !important;
    font-weight: 400;
    color: $vgc-grey;
    text-fill-color: unset;
    -webkit-text-fill-color: unset;
  }

  // Placeholder loading style for videos
  .entry-content-asset--video {
    background: $bytes-grey;
  }

}

.vgc-embed {
  margin-left: -$spacing_sm;
  margin-right: -$spacing_sm;
}

.entry-content-asset--message {
  background: $vgc-purple;
  color: $vgc-white;
  p {
    margin-bottom: 0px;
  }
}
.asset--hidden {
  margin: 0px !important;
  padding: 0px !important;
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .vgc-embed {
    margin-left: 0;
    margin-right: 0;
  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/