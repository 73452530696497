/*************************************************************************************************/
/** CSS Bundle - Frontend ************************************************************************/
/*************************************************************************************************/

// VGC functions
@import "functions";

// VGC mixins
@import "mixins/utils";
@import "mixins/typography";
@import "mixins/media";
@import "mixins/post-variants";
@import "mixins/components";

// VGC variables
@import "colours";
@import "fonts";
@import "settings";

// Bootstrap
@import "bootstrap";
//
// Any defaults from ../../bower_components/bootstrap/scss/_variables.scss that
// need to be overridden should be done above. Also: breakpoints and grid vars!

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap-custom.scss";
// endbower

// Plugins


/*

  What is an ELEMENT, how is it different from a COMPONENT, and
  how are they both different from REUSABLES?! He who finds the
  answer to this question shall have achieved enlightenment.

*/

// VGC elements
@import "elements/typography";
@import "elements/lists";
@import "elements/quotes";
@import "elements/images";
@import "elements/forms";
@import "elements/embeds";

// VGC components
@import "components/wordpress-admin-bar";
@import "components/author-excerpt";
@import "components/author-byline";
@import "components/page-headings";
@import "components/review-details";
@import "components/pagination";
@import "components/tags";
@import "components/highlights";
@import "components/object-header";
@import "components/object-meta";
@import "components/socialshare";
@import "components/comments";
@import "components/gallery";
@import "components/video";
@import "components/alert-newsflash";
@import "components/alert-event-highlights";
@import "components/alert-podcast-promo";
@import "components/alert-patreon-promo";
@import "components/sponsored";
@import "components/archive";
@import "components/productsblock";
@import "components/inbrief";

// VGC shortcodes
@import "shortcodes/patchnotes";
@import "shortcodes/boxout";
@import "shortcodes/update";
@import "shortcodes/related";
@import "shortcodes/vid";
@import "shortcodes/product";

// VGC reusables
@import "reusables/listing";
@import "reusables/pageheader";
@import "reusables/product";

// VGC pages
@import "elements/posts";

// VGC global
@import "global/common";
@import "global/header";
@import "global/layout";
@import "global/footer";
@import "global/adverts";

// Plugins
@import "plugins/featherlight";
@import "plugins/featherlight-gallery";
@import "plugins/cookiecontrol";
@import "plugins/onesignal";

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

// Korn's random temporary shit
div.debug {
  outline: 1px dotted rgba(#f0f, 0.25);
  background: rgba(#f0f, 0.05);  
  padding: 2px 5px 1px 5px;
  margin-bottom: 5px;
  font-family: monospace;
  font-size: 10px;
}
@if ($debug == false) {
  div.debug {
    display: none !important;
  }
}

// Debug for <rel> tags, only on dev
rel {
  //color: #f0f;
  border-bottom: 2px dotted rgba(255,0,255,0.33);
  &:hover {
    color: #f0f;
    border-bottom: 2px solid rgba(255,0,255,1);
  }
}

/*
h6 {
  outline: 1px dashed #f0f !important;
}
h5 {
  outline: 2px dashed #f0f !important;
}
h4 {
  outline: 3px dashed #f0f !important;
}
h3 {
  outline: 4px dashed #f0f !important;
}
h2 {
  outline: 5px dashed #f0f !important;
}
h1 {
  outline: 6px dashed #f0f !important;
}
*/

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/