/*************************************************************************************************/
/** Product listings *****************************************************************************/
/*************************************************************************************************/

.vgc-productlisting {

  .vgc-product {
    display: flex;

    &__image {
      flex: 1;
      margin: 0;
      margin-right: $spacing_sm;

      min-width: 60px;
      min-height: 60px;
      max-width: 60px;
      max-height: 60px;

      background: #fff;//rgba(255,0,255,0.2);

      display: flex;
      align-content: center;
      justify-content: center;

      img {
        background: #fff;//rgba(255,0,255,0.2);
        object-position: center center;
        object-fit: scale-down;
      }      
    }
    &__info {
      flex: 1;

      .headline, h1, h2, h3, h4, h5, h6 {
        @include brand-font();
        line-height: 1.2;
        display: block;

        margin: 0;
        padding: 0;

        font-size: 1.1rem;
        margin-bottom: 9px;

        //text-align: right;
      }

      .links {
        display: flex;
        flex-wrap: wrap;
        gap: $spacing_sm;
        min-height: 30px; // CLS fix

        //justify-content: flex-end;
        
        .product-link,
        .edit-link {
          display: flex;

          height: 30px;
          line-height: 30px;
          font-size: 0.8rem;

          border-radius: 5px;
          overflow: hidden;

//          min-width: calc(50% - 10px); // same as parent's gap ^^

//          @include corner-bit("5px"); // no!

          // default
//          background: linear-gradient(90deg, $vgc-orange, $vgc-yellow);
//          background: linear-gradient(90deg, $vgc-lightgrey, $vgc-grey);
          background: $vgc-grey;

          // branded versions
          &--amazon {
            background: #000;
          }            
          &--walmart {
            background: #017cc2;
          }
          &--bestbuy {
            background: #0046bf;
          }
          &--game {
            background: #a83997;
          }
          &--nintendo-uk {
            background: #e70013;
          }

          &:hover, &:focus {
            position: relative;
            top: -1px;
            box-shadow: 1px 1px 5px rgba(0,0,0,0.33);
          }
          
          .retailer {
            height: 30px;
            width: auto;
          }

          .price,
          .stock { 
            color: #fff;
            padding-left: $spacing_sm;

            &:last-of-type {
              padding-right: $spacing_sm;
            }

            &--discounted {
              opacity: 0.66;
              font-size: 0.7rem;
              text-decoration: line-through;
            }
          }

        }
        .edit-link {
          background: linear-gradient(90deg, $vgc-orange, $vgc-yellow);
          padding-left: 10px;
          padding-right: 10px;
          color: #fff;
          font-size: 0.8rem;
        }
        .product-link {
          display: none;
        }
      }

    }
  }

}

.product-link.right-region {
  display: flex !important;
}
.product-link.wrong-region {
  display: none !important;
}

body.admin-bar {
  .product-link.wrong-region {
    display: inline-block !important;
    opacity: 0.1;
    order: 999;
    .retailer {
      display: none;
    }
    &:hover, &:focus {
      opacity: 1;
    }
  }
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) {
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {
}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-only(lg) {}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-only(xl) {}