// Author byline

.author-byline {
  span {
    display: block;
    color: $vgc-grey;
    @include smallest-text();
    margin-top: $spacing_sm / 2;
  }

  h4, span.h4 {
    @include brand-font();
    display: block;

    @include heading-scale("sm", "4");
    white-space: nowrap;
    margin: 0;

    a {
      color: $vgc-black;
    }
  }
}


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .author-byline {

    h4, span.h4 {
      @include heading-scale("md", "5");
    }

  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  .author-byline {

    h4, span.h4 {
      @include heading-scale("lg", "5");
    }
      
  }

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/