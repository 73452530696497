/*************************************************************************************************/
/** Page headings etc.  **************************************************************************/
/*************************************************************************************************/

.page-header {
  margin-bottom: $spacing_sm;

  h1,h2 {
    @include heading-scale("sm","1");
    @include heading-underlined("small");
    text-transform: uppercase;
    color: $vgc-black;
  }

  ul {
    @include list-plain();
    @include list-inline();
    margin-top: $spacing_sm / 2;

    li:not(:last-of-type) {
      margin-right: 0; // tags have their own margins.
    }

    .vgc-tag {
      margin-right: $spacing_sm / 2;
      margin-bottom: $spacing_sm / 2;
    }

  }

}
.page-header--secondary {
  margin-top: $spacing_sm;
}

body.category-reviews .page-header h1,
body.category-review .page-header h1,
body.category-features .page-header h1,
body.category-interviews .page-header h1,
body.category-opinion .page-header h1, {
  @include heading-underlined("small", "purple");
}

body.category-blog .page-header h1,
body.category-podcast .page-header h1 {
  @include heading-underlined("small", "blue");
}

body.category-guide .page-header h1 {
  @include heading-underlined("small", "red");
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .page-header {
    margin-bottom: $spacing_md;

    h1,h2 {
      @include heading-scale("md","1");
      @include heading-underlined("normal");
    }

  }

  .page-header--secondary {
    margin-top: $spacing_md;
  }

  body.category-reviews .page-header h1,
  body.category-review .page-header h1,
  body.category-features .page-header h1,
  body.category-interviews .page-header h1,
  body.category-opinion .page-header h1, {
    @include heading-underlined("normal", "purple");
  }

  body.category-blog .page-header h1,
  body.category-podcast .page-header h1 {
    @include heading-underlined("normal", "blue");
  }

  body.category-guide .page-header h1 {
    @include heading-underlined("normal", "red");
  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  .page-header {
    margin-bottom: $spacing_lg;

    h1,h2 {
      @include heading-scale("lg","1");
      @include heading-underlined("large");
    }

  }
  .page-header--secondary {
    margin-top: $spacing_lg;
  }
  .page-header--alt {
    margin-bottom: $spacing_md;
  }

  body.category-reviews .page-header h1,
  body.category-review .page-header h1,
  body.category-features .page-header h1,
  body.category-interviews .page-header h1,
  body.category-opinion .page-header h1, {
    @include heading-underlined("large", "purple");
  }

  body.category-blog .page-header h1,
  body.category-podcast .page-header h1 {
    @include heading-underlined("large", "blue");
  }

  body.category-guide .page-header h1 {
    @include heading-underlined("large", "red");
  }

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/