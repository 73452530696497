/*************************************************************************************************/
/** Layout ***************************************************************************************/
/*************************************************************************************************/

.vgc-layout {
  
  background: {
    color: #dbd7d5;
    image: url('../images/bg.png');
  }

}

.vgc-layout__document {
  background: #fff;
  position: relative;
  min-height: 50vh;
}

.vgc-layout__main,
.vgc-layout__sidebar,
.vgc-layout__section,
.vgc-layout__promos {  
  padding: $spacing-sm;
}
@if ($debug == true) {
  .vgc-layout__main {
    outline: 1px dotted rgba(#f00, 0.25);
    background: rgba(#f00, 0.05);
  }
  .vgc-layout__sidebar {
    outline: 1px dotted rgba(#00f, 0.25);
    background: rgba(#00f, 0.05);
  }
  .vgc-layout__adbreak {
    outline: 1px dotted rgba(#f0f, 0.25);
    background: rgba(#f0f, 0.05);
  }
  .vgc-layout__promos {
    outline: 1px dotted rgba(#0f0, 0.35);
    background: rgba(#0f0, 0.1); 
  }
}

.vgc-layout__sidebar {
  section {
    margin-bottom: $spacing-sm;
  }
}

.vgc-layout__adbreak {
  padding-top: $spacing-sm;
  padding-bottom: $spacing-sm;  
}
.vgc-layout__promos {
  
}

.vgc-layout__pod {

  header {

    > div:first-child {
      // border-bottom: 2px solid $vgc-orange;
      // padding-bottom: $spacing_sm / 2;
    }

    h1 {
      @include heading-scale("sm","2");
      line-height: 1.1;
      margin: 0 0 ($spacing_sm / 2) 0;
    }
    p {
      @include small-text();
      margin: 0;
      color: $vgc-grey;
    }
    margin-bottom: $spacing_sm;

    .vgc-socialshare {
      display: none;
    }
  }

  &-main {
  }

  &-aside {
    display: none;

    &-footer {
      ul {
        @include list-plain();
      }

    }
  }

}

.vgc-layout__main {
  .vgc-layout__pod {
    &-aside {

      .vgc-socialshare {
        margin-bottom: $spacing_lg;
      }
      
    }
  }

  // Show aside on first homepage pod (for popular)
  .vgc-layout__pod--1 {
    display: flex;
    flex-direction: column;
    header {
      order: 1;
      text-align: center;
      //padding-top: $spacing_sm;
    }
    .vgc-layout__pod-aside {
      display: block;
      order: 2;
      margin-bottom: $spacing_sm / 2;
      header {
        display: none;
      }
      .vgc-listing--popular {
        article:nth-of-type(n+6) {
          display: none;
        }
      }
      .vgc-podcast-promo { 
        display: none;
      }
    }
    .vgc-layout__pod-main {
      > h4,
      > span.h4 {
        @include brand-font();
        display: block;

        @include heading-scale("lg","6");
        @include heading-block("small");
      }
      order: 3;
    }
  }

}

.vgc-layout__section {
  .vgc-layout__pod {
    border-top: 2px solid $vgc-lightgrey;
    padding-top: $spacing-sm * 2;
  }  
}

.vgc-layout__pod--heading {
  display: block !important;
  border-top: 0 !important;
  padding-top: 0 !important;
  padding-bottom: $spacing-sm;
  //background: rgba(255,0,255,0.1);
}

.vgc-layout__404 {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h1 {
    @include heading-scale("sm","0");
    margin: 0;
  }
  h2 {
    @include heading-scale("sm","3");
    color: $vgc-grey;
  }
}


.vgc-layout__section,
.vgc-layout__404 {
  width: 100%;
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .vgc-layout__main,
  .vgc-layout__sidebar,
  .vgc-layout__section,
  .vgc-layout__promos {  
    padding: $spacing-md;
  }  

  .vgc-layout__sidebar {
    section {
      margin-bottom: $spacing-md;
    }
  }

  .vgc-layout__adbreak {
    padding-top: $spacing-md;
    padding-bottom: $spacing-md;
  }

  .vgc-layout__pod {
    header {
      h1 {
         @include heading-scale("md","2");
         line-height: 1.1;
      }
    }

    &-main {
      margin-bottom: $spacing_lg;
    }

    &-aside {
    }

  }

  .vgc-layout__section {
    .vgc-layout__pod {
      padding-top: $spacing-md * 2;
    }
  }

  .vgc-layout__404 {
    h1 {
      @include heading-scale("md","0");
    }
    h2 {
      @include heading-scale("md","3");
    }
  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  .vgc-layout__document {
    @include center-block();
    width: 970px;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 1px 1px 5px rgba(#000, 0.33);
  }  
  .vgc-layout__main {
    max-width: 970px;
  }

  .vgc-layout__main {
    flex: 1;
  }

  .vgc-layout__main {
    
    // Unfuck what we did to the aside
    .vgc-layout__pod--1 {
      display: block;
      flex-direction: unset;
      header {
        order: 1;
        text-align: unset;        
      }
      .vgc-layout__pod-aside {
        display: none;
        order: 3;
        margin-bottom: unset;
        header {
          display: block;
        }
        .vgc-listing--popular {
          article:nth-of-type(n+6) {
            display: flex;
          }
        }
        .vgc-podcast-promo { 
          display: block;
        }
      }
      .vgc-layout__pod-main {
        > h4 {
          display: none;
        }
        order: 2;
      }
    }

  }

  .vgc-layout__sidebar {
    flex: 0 0 351px;
    padding: $spacing-md $spacing-md $spacing-md 0;

    // Needed for GN auto ad insertion:
    display: flex;
    flex-direction: column;
  }
  .vgc-layout__break,
  .vgc-layout__adbreak {
    flex-basis: 100%;
  }

  .vgc-layout__document--sidebar {
    .vgc-layout__main {
      max-width: 619px; // CLS fix for video player
      .post__content-main {
        max-width: 619px; // CLS fix for video player
      }
    }
  }

  .vgc-layout__pod {
    header {
      h1 {
         @include heading-scale("lg","3");
         line-height: 1.1;
      }
    }

    &-main {

      // some things need reducing now due to space considerations
      .vgc-listing {
        .headline, h1, h2, h3, h4, h5 {
          @include heading-scale("lg","4");
        }
      }
      .vgc-highlights--a {
        .headline, h1, h2, h3, h4, h5 {
          @include heading-scale("lg","1");
        }
      }


    }

    &-aside {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      header {
        display: none;
      }

      > section,
      .vgc-layout__pod-aside-footer {
        width: 49%;
        margin-bottom: $spacing_md;
      }      
    }

  }  

  .vgc-layout__section {
    .vgc-layout__pod {
      // padding-top: $spacing-lg * 2; // no!

      display: flex;
      flex-wrap: wrap;

      header {
        width: 100%;
        flex: unset;
        margin-right: 0;     
      }
      &-main {
        flex: 5;
      }
      &-aside {
        flex: 0 0 351px;
        margin-left: $spacing_md;

        > section,
        .vgc-layout__pod-aside-footer {
          width: 100%;
        }
      }

    }
  }  

  .vgc-layout__404 {
    h1 {
      @include heading-scale("lg","0");
    }
    h2 {
      @include heading-scale("lg","3");
    }
  }

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {

  .vgc-layout__document {    
    width: 1260px;
  }  
  .vgc-layout__main {
    max-width: 1260px;
  }

  .vgc-layout__main,
  .vgc-layout__section,
  .vgc-layout__promos {  
    padding: $spacing-lg;
  }
  .vgc-layout__document--sidebar {
    .vgc-layout__main {
      max-width: 909px; // CLS fix for video player
      padding-right: $spacing-lg;
    }
  }

  .vgc-layout__main {
    
    // Unfuck what we did to the aside
    .vgc-layout__pod--1 {
      display: flex;
      flex-direction: row-reverse;
      .vgc-layout__pod-aside {
        display: block;
      }
    }

  }

  .vgc-layout__main--secondary {
    padding-top: $spacing-lg;
  }

  .vgc-layout__adbreak {
    padding-top: $spacing-lg;
    padding-bottom: $spacing-lg;
  }

  .vgc-layout__pod {
    display: flex;
    flex-wrap: wrap;

    header {
      flex: 0;
      
      .vgc-socialshare {
        display: flex;
        margin-top: $spacing_lg;
      }
    }

    &-main {
      flex: 5;
      order: 1;
      margin-bottom: 0;
    }

    &-aside {
      flex: 2;
      order: 2;
      margin-left: $spacing_lg;
      flex-direction: column;
      justify-content: flex-start;

      > header {
        margin-bottom: $spacing_md;
      }

      > section,
      .vgc-layout__pod-aside-footer {
        width: 100%;
        margin-bottom: $spacing_lg;
      }

      .vgc-layout__pod-aside-footer {        
        margin-bottom: 0;
      }

      &-footer {
        margin-top: auto;
      }

      > section.vgc-podcast-promo {
        img { 
          width: 100%;
        }
      }
    }

  }
  .vgc-layout__main {
    .vgc-layout__pod {

      > header {
        display: none;
      }

      &-main {
        order: 2;
      }

      &-aside {
        order: 1;
        margin-right: $spacing_lg;
        margin-left: 0;

        > header {
          display: block;
        }
      }

    }
    .vgc-layout__pod--heading {
      > header {
        display: block;
      }
    }
  }

  .vgc-layout__section {
    .vgc-layout__pod {
      header {
        flex: 2;
        align-self: flex-start;
        margin-right: $spacing_lg;
      }
      &-aside {
        flex: 0 0 285px;
        margin-left: $spacing_lg;
        align-items: flex-start;
      }
    }
  }


}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/