// Highlights
// These are the blocks of X stories that appear at the top of the homepage (and other indexes)
// to promote stories outside of the standa listings.


.vgc-highlights {
  
  &-header {
    h4 {
      @include heading-scale("lg","6");
      @include heading-block("small");
      margin-bottom: $spacing_sm;
    } 
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: lighten($vgc-lightgrey, 5%);
    padding: $spacing_sm / 2;
    margin-top: $spacing_sm;

    span {
      display: inline-block;
      padding: 0 $spacing_sm;
      color: $vgc-grey;
    }

    a {
      display: inline-block;
      white-space: nowrap;
      padding: .35em 1em .5em 1em; // Lato has a weird cap-height too!
      font-weight: bold;
      text-transform: uppercase;
      text-align: right;
      background-color: $vgc-purple;
      color: $white;

      &:hover,
      &:visited:hover {
        color: $white;
        background-color: $vgc-orange;
        text-decoration: none;
      }
    }
  }

}

.vgc-highlights--a, 
.vgc-highlights--b, 
.vgc-highlights--c, 
.vgc-highlights--d, 
.vgc-highlights--e, 
.vgc-highlights--f,
.vgc-highlights--g {

  > .vgc-listing {

    .vgc-post {
      &__info {
        h1, h2, h3, h4, h5, h6 {
//          text-transform: uppercase;
        }
      }
    }

  }

}

// One massive story
.vgc-highlights--a {


  .vgc-post {
    display: block;
    position: relative;
    @include corner-bit();

    &__media {
      margin: 0;

      .bytes-image {
        //padding-top: percentage(4 / 3);
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        @include background-gradient("0deg", rgba($vgc-orange, 1), rgba($vgc-yellow, 0));
      }

    }
    &__info {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: $spacing_sm;
      width: 100%;
      color: $white;

      .meta, .meta a {
        color: $vgc-white;
      }

      h1, h2, h3, h4, h5, h6 {
        @include heading-scale("sm", "1");
        font-weight: 700 !important;
        line-height: 1;
        text-shadow: 0 0 5px rgba($vgc-purple, .15);

        a {
          color: $white !important;
        }
      }

      .strapline {
        display: none;
      }

    }

    &--important,
    &--featured.vgc-post--important {
      .vgc-post {
        &__info {
          h1, h2, h3, h4, h5, h6 {
            a {
              background-color: transparent !important;
              padding: 0 !important;
              line-height: 1 !important;
              
              &:hover,
              &:visited:hover,
              &:active {
                background-color: none !important;
                color: $vgc-white !important;
              }              
            }
          }
        }
      }      
    }

  }

  .vgc-post--featured {

    .vgc-post__media {

      &::after {
        @include background-gradient("0deg", rgba($vgc-purple, 1), rgba($vgc-plum, 0));
      }
    }

  }

  .vgc-highlights-footer {
    margin-top: $spacing_sm;
  }

}

// Two big stories
.vgc-highlights--b {

}

// One bigger story
.vgc-highlights--c,
.vgc-highlights--f {

  .vgc-post {
    display: block;
    position: relative;
    margin-bottom: $spacing_md;

    .vgc-post__media {
      margin: 0 0 $spacing_sm 0;

      .bytes-image {
        padding-top: percentage(9 / 16);
      }
    }

    .vgc-post__info {
      h1, h2, h3, h4, h5, h6 {
        @include heading-scale("sm", "1");
        line-height: 1;
      }
    }

  }

}

// Three minor articles
.vgc-highlights--d {
}

// Two very minor articles
.vgc-highlights--e {
}


// One big story (left/right)
.vgc-highlights--f {
}

// Stright up stacked block listings, meant for small spaces.
.vgc-highlights--g {

  .vgc-post {
    display: block;

    &__media {
      margin: 0 0 $spacing_sm 0;
    }

    &__info {
      h1, h2, h3, h4, h5, h6 {
        @include heading-scale("sm","4");
      }
    }

  }

}

// Embiggen review stars on big stories
.vgc-highlights--a,
.vgc-highlights--c {
  
  .vgc-post__icon,
  .vgc-post__count {
//    transform: scale(1.2);
  }
  .vgc-post__score {
    transform: translateX(-50%) translateY(-50%) scale(1.2);
  }
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {

  .vgc-highlights {
  }

  // One massive story
  .vgc-highlights--a {
  }

  // Two big stories
  .vgc-highlights--b {
  }

  // Three stories (first one is a bit bigger)
  .vgc-highlights--c {
  }

  // The minor articles
  .vgc-highlights--d {
  }

  // One big story (left/right)
  .vgc-highlights--f {
  }

  // Stright up stacked block listings
  .vgc-highlights--g {
  }

}

@include media-breakpoint-only(sm) {
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  // Embiggen review stars on big stories
  .vgc-highlights--a,
  .vgc-highlights--c {
    
    .vgc-post__icon,
    .vgc-post__count {
  //    transform: scale(1.2);
    }
    .vgc-post__score {
      transform: translateX(-50%) translateY(-50%) scale(2);
    }
  }
  .vgc-highlights--b {
    .vgc-post__score {
      transform: translateX(-50%) translateY(-50%) scale(1.2);
    } 
  }

  .vgc-highlights {

    &-header {
      h4 {
        margin-bottom: $spacing_md;
      } 
    }
    &-footer {
      margin-top: $spacing_md;
    }

  }

  // One massive story
  .vgc-highlights--a {

    .vgc-post {
      @include corner-bit("20px");

      &__media {
        .bytes-image {
          padding-top: percentage(9 / 16);
        }
      }

      &__icon {
        top: $spacing_md;
        left: $spacing_md;
      }

      &__score {
        top: $spacing_md;
        right: $spacing_md;
      }

      &__info {
        padding: $spacing_md;

        h1, h2, h3, h4, h5, h6 {
          @include heading-scale("md", "0");
          line-height: 1;
        }
      }

    }

  }

  // Two big stories
  .vgc-highlights--b {

    .vgc-listing {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  
    .vgc-post {
      width: 49%;
      display: block;
      position: relative;

      &:nth-last-child(-n+2){
        margin-bottom: 0;
      }

      &__media {
        margin: 0 0 $spacing_sm 0;

        .bytes-image {
          padding-top: percentage(9 / 16);
        }
      }

      .vgc-post__info {
        h1, h2, h3, h4, h5, h6 {
          @include heading-scale("sm", "1");
          line-height: 1;
        }
      }

    }    

    // When there are two of these blocks, instead of one block with > 2 in it.
    + .vgc-highlights--b {
      margin-top: $spacing_md;
    }

  }

  // One bigger story
  .vgc-highlights--c {

    .vgc-post {

      .vgc-post__info {
        h1, h2, h3, h4, h5, h6 {
          @include heading-scale("md", "1");
        }
      }

    }

  }

  // Three minor articles
  .vgc-highlights--d {

    .vgc-post {
      
      &__info {
        h1, h2, h3, h4, h5, h6 {
          @include heading-scale("md", "2");
        }
      }

    }

  }

  // Two very minor articles
  .vgc-highlights--e {

  }

  // One big story (left/right)
  .vgc-highlights--f {

    .vgc-post {
      display: flex;
      margin-bottom: 0;

      .vgc-post__media {
        margin: 0 $spacing_md 0 0;
      }

      .vgc-post__info {
        flex: 2;
        h1, h2, h3, h4, h5, h6 {
          @include heading-scale("md", "2");
          line-height: 1;
        }
      }

    }

  }

  // Stright up stacked block listings
  .vgc-highlights--g {
    .vgc-post {

      &__info {
        h1, h2, h3, h4, h5, h6 {
          @include heading-scale("md","4");
        }
      }

    }    
  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  .vgc-highlights {
    &-footer {
      margin-top: $spacing_lg;
    }
  }

  // One massive story
  .vgc-highlights--a {

    .vgc-post {

      &__icon {
        top: $spacing_lg;
        left: $spacing_lg;
      }
      
      &__score {
        top: $spacing_lg;
        right: $spacing_lg;
      }

      &__info {
        padding: $spacing_lg;
      }

    }

  }

  // Two big stories
  .vgc-highlights--b {

    .vgc-post {
      width: 48%;

      &__info {
        h1, h2, h3, h4, h5, h6 {
          @include heading-scale("lg", "4");
        }
      }

    }

    // When there are two of these blocks, instead of one block with > 2 in it.
    + .vgc-highlights--b {
      margin-top: $spacing_lg;
    }

  }

  // One bigger story
  .vgc-highlights--c {

    .vgc-post {

      .vgc-post__info {        
        h1, h2, h3, h4, h5, h6 {
          @include heading-scale("lg", "2");
        }
      }

    }

  }

  // Three minor articles
  .vgc-highlights--d {
  }

  // Two very minor articles
  .vgc-highlights--e {

    .vgc-listing {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .vgc-post {
      width: 49%;
      &:nth-last-child(-n+2){
        margin-bottom: 0;
      }

      .vgc-post__media {
        margin-right: 0;
      }

      .vgc-post__info {
        padding-left: $spacing_md;
        h1, h2, h3, h4, h5, h6 {
          @include heading-scale("lg", "4"); 
        }
      }

    }

  }

  // One big story (left/right)
  .vgc-highlights--f {

    .vgc-post {

      .vgc-post__info {
        h1, h2, h3, h4, h5, h6 {
          @include heading-scale("lg", "3");
          line-height: 1;
        }
      }

    }

  }

  // Stright up stacked block listings
  .vgc-highlights--g {
    .vgc-post {

      &__info {
        h1, h2, h3, h4, h5, h6 {
          @include heading-scale("lg","5");
        }
      }

    }   
  }

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {

  .vgc-highlights {
  }

  // One massive story
  .vgc-highlights--a {

    .vgc-post {

      &__info {
        h1, h2, h3, h4, h5, h6 {
          @include heading-scale("lg", "0");
          line-height: 1;
        }
      }

    }

  }

  // Two big stories
  .vgc-highlights--b {

    .vgc-post {
      width: 49%;

      &__info {
        h1, h2, h3, h4, h5, h6 {
          @include heading-scale("lg", "3"); // the same as standar listings.
        }
      }

    }

  }

  // One big story
  .vgc-highlights--c {

    .vgc-post {
      margin-bottom: $spacing_lg;
    }

  }

  // Three minor articles
  .vgc-highlights--d {

    .vgc-listing {
      display: flex;
      justify-content: space-between;
    }

    .vgc-post {
      display: block;
      width: 32.333%;
      &:nth-last-child(-n+3){
        margin-bottom: 0;
      }
      
      &__media {
        margin: 0 0 $spacing_sm 0;
      }

      &__info {
        h1, h2, h3, h4, h5, h6 {
          @include heading-scale("lg", "4");
        }
      }

    }

  }

  // Two very minor articles
  .vgc-highlights--e {

  }

  // One big story (left/right)
  .vgc-highlights--f {
  }

  // Stright up stacked block listings
  .vgc-highlights--g {
  }

}

@include media-breakpoint-only(xl) { }