/*************************************************************************************************/
/** Wordpress Admin Bar **************************************************************************/
/*************************************************************************************************/

// Account for the Wordpress Admin Bar being present...
body.admin-bar {
  #wpadminbar {
    position: fixed !important; // stop breaking out of mmenu container
    background: #333 !important;
  }
  margin-top: 32px !important;  
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

// ...and match its own breakpoints
@media screen and (max-width: 782px) {

  body.admin-bar { 
    margin-top: 46px !important;     
  }
  
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/