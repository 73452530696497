/*************************************************************************************************/
/** Fonts & Font Mixins **************************************************************************/
/*************************************************************************************************/

// Lato (local) via https://google-webfonts-helper.herokuapp.com/fonts/lato?subsets=latin

// Note: preload all (or most important) of these in <head> !

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url(add_asset_path('fonts/lato-v23-latin-regular.woff2')) format('woff2'),
       url(add_asset_path('fonts/lato-v23-latin-regular.woff')) format('woff');
  font-display: swap;
}

/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url(add_asset_path('fonts/lato-v23-latin-italic.woff2')) format('woff2'),
       url(add_asset_path('fonts/lato-v23-latin-italic.woff')) format('woff');
  font-display: swap;
}

/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url(add_asset_path('fonts/lato-v23-latin-700.woff2')) format('woff2'),
       url(add_asset_path('fonts/lato-v23-latin-700.woff')) format('woff');
  font-display: swap;
}

/* lato-700italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url(add_asset_path('fonts/lato-v23-latin-700italic.woff2')) format('woff2'),
       url(add_asset_path('fonts/lato-v23-latin-700italic.woff')) format('woff');
  font-display: swap;
}

/* Kelson - regular */  
@font-face {
  font-family: 'Kelson';
  font-weight: 400;
  src: local(''),
       url(add_asset_path('fonts/kelson-medium.woff2')) format('woff2'),
       url(add_asset_path('fonts/kelson-medium.woff')) format('woff');       
  font-display: swap;
}

/* Kelson - bold */
@font-face {
  font-family: 'Kelson';
  font-weight: 700;
  src: local(''),
       url(add_asset_path('fonts/kelson-bold.woff2')) format('woff2'),
       url(add_asset_path('fonts/kelson-bold.woff')) format('woff');       
  font-display: swap;
}

/*  
@font-face {
  font-family: 'Kelson';
  font-weight: 900;
  src: url(add_asset_path('fonts/kelson-extrabold.eot'));
  src: url(add_asset_path('fonts/kelson-extrabold.eot?#iefix')) format('embedded-opentype'),
       url(add_asset_path('fonts/kelson-extrabold.woff2')) format('woff2'),
       url(add_asset_path('fonts/kelson-extrabold.woff')) format('woff'),
       url(add_asset_path('fonts/kelson-extrabold.ttf')) format('truetype');
  font-display: swap;
}
*/

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/