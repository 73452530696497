/*************************************************************************************************/
/** Article listings *****************************************************************************/
/*************************************************************************************************/

.vgc-listing {

  .vgc-post {
    margin-bottom: $spacing_sm;
    
    .headline, h1, h2, h3, h4, h5, h6 {
      @include brand-font();
      line-height: 1.2;

      display: block;
      font-weight: 500;
    }


    &--important {
      @include post-important();
    }

    &--featured.vgc-post--important {
     @include post-important($vgc-purple); 
    }

    &--inbrief {
      .vgc-post__info {
        .headline, h1, h2, h3, h4, h5, h6 {
          a {
            color: $vgc-grey;
            &:active {
              color: $vgc-yellow;
            }
            &:hover,
            &:visited:hover {
              color: $vgc-orange;
            }
          }
        }
        .strapline {
          display: none;
        }
      }
    }

  }

}

.vgc-listing--index {
  //background: rgba(#f00, 0.1);

  .vgc-post {
    margin-bottom: $spacing_lg;
  }

}
 

.vgc-listing--search {
  //background: rgba(#ff0, 0.1);
}

.vgc-listing--popular {
  //background: rgba(#0f0, 0.1);

  .vgc-post {
    align-items: center;

    &:not(:last-child){
      margin-bottom: $spacing_sm;
      padding-bottom: $spacing_sm;
      border-bottom: 1px solid $vgc-lightgrey; 
    }
    
    .headline, h1, h2, h3, h4, h5, h6 {
      @include heading-scale("lg","5");
      margin: 0;
    }

    &__prefix {
      color: $vgc-grey !important;
      font-size: 2rem !important;
      font-weight: 400 !important;
      padding-bottom: 0.15em;
      border-right: 2px solid $vgc-orange;
      border-image-source: linear-gradient(180deg, rgba($vgc-yellow, 0.95), rgba($vgc-orange, 0.95));
      border-image-slice: 1;
      padding-right: $spacing_sm / 2;
    }

    .meta {
      margin-top: 5px;
    }

    // Popular things in popular listings are a bit different
    &--sponsored {
      flex-wrap: wrap;

      .sponsored-text {
        width: 100%;
        margin-bottom: $spacing_sm / 2;
      }

      // The sponsored icon
      .vgc-post__prefix {
        figure {
          width: 20px;
          height: auto;
          margin: 0;
        }
      }

    }

  }

}

.vgc-listing--stream { 
  //background: rgba(#00f, 0.1);
}

.vgc-listing--related {

  .vgc-post {
    &__info {
      .headline, h1, h2, h3, h4, h5, h6 {
        margin: 0;
      }
      .meta {
        margin-top: $spacing_sm / 2;
      }
    }
  }

}
.vgc-listing--relatedreviews {

  padding-bottom: 1em;

}

.vgc-dateheader {

  position: relative;
  text-align: center;

  //background: $vgc-lightgrey;  
  //padding: 10px 5px;

  margin-bottom: 15px;

  &::before {
    display: block;
    content: " ";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    height: 2px;
    background: $vgc-lightgrey;
  }

  h1,h2,h3,h4,h5,h6 {    
    display: inline-block;
    position: relative;

    margin: 0;
    padding: 0 15px;
    
    background: #fff;
    color: $vgc-grey;
  }

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

.vgc-post {

  display: flex;
  align-items: flex-start;  

  &__media {

    flex: 2;
    margin-right: $spacing_sm;

    a {
      display: block;
      line-height: 0;
    }

    .bytes-image {
      padding-top: percentage(1 / 1);      
      overflow: hidden;
      @include corner-bit();
    }
    
  }

  // An icon to represent the type of content this is
  &__icon {
    display: block;
    position: absolute;
//    top: $spacing_sm / 2;
//    left: 9px + $spacing_sm / 2;
    top: 0;
    left: 7px;
    @include background-gradient("45deg", rgba($vgc-yellow, 0.95), rgba($vgc-orange, 0.95));
//    backdrop-filter: blur(2px);
//    padding: $spacing_sm / 2;
    padding: 7px;
    
    box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.33);

    transform-origin: top left;

    img, svg {
      fill: #fff;
      display: inline-block;
      width: 12px;
      height: 12px;
    }
    span {
      display: none;
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      margin-left: 5px;
//      position: relative;
//      top: 1px;
    }
  }

  // An overlaid "play" button to indicate featured_video present
  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    width: 30px;
    height: 32px;

    @include background-gradient("45deg", rgba($vgc-orange, 0.9), rgba($vgc-yellow, 0.9));

    clip-path: polygon(5% 0, 5% 100%, 95% 50%);
  }

  // Review score added to listings which are of a review
  &__score {
    @include scoreblock();
    display: flex; // by default the image in a listing is too small for these, so they need turning on later.
    position: absolute;
//    top: 0;
//    right: 0;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.5);
    padding: 7px;

    box-shadow: -1px 1px 2px 0px rgba(0,0,0,0.33);    

    // can't use the white corner block here, and clip-path for right angle triangles doesn't work because percentages.
    &::after {
      display: none;
    }    

    img, svg {
      fill: #fff;
      width: 16px;
      height: 16px;
      margin: 0 $spacing_sm / 4;
    }

  }

  // A colour ident added to all posts [standard|featured|blog|object]
  &__ident {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 7px;
    @include background-gradient("0deg", rgba($vgc-orange, 0.9), rgba($vgc-yellow, 0.9));

    box-shadow: -2px 0px 2px -1px rgba(0,0,0,0.33), 2px 0px 2px -1px rgba(0,0,0,0.33);    

  }

  // A counter, either for comments (on articles) or number of articles (on objects)
  &__count {    
    position: absolute;
    top: 0;
    right: 0;
    padding: 7px;
    @include background-gradient("45deg", rgba($vgc-orange, 0.95), rgba($vgc-yellow, 0.95));

    box-shadow: -1px 1px 2px 0px rgba(0,0,0,0.33);

    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;

    transform-origin: top right;

    img, svg {
      fill: #fff;
      display: inline-block;
      width: 12px;
      height: 12px;
//      margin-right: $spacing_sm / 2;
    }

    span {
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      margin-left: 5px;
//      position: relative;
//      top: 1px;
    }

  }

  // A debug rank number, only for logged-in editors
  &__rank {    
    position: absolute;
    bottom: $spacing_sm / 2;
    left: 8px + $spacing_sm / 2;
    padding: $spacing_sm $spacing_sm / 2;
    @include background-gradient("45deg", rgba($vgc-orange, 0.95), rgba($vgc-yellow, 0.95));

    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;

    box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.33);
  }

  // A prefix used instead of __media, e.g. on popular lists
  &__prefix {
    flex: 0 45px;
    margin-right: $spacing_sm;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 40px;    

    @include brand-font();
    @include heading-scale("sm", "4");    
    color: $vgc-orange;
    line-height: 1;
    font-weight: 700;

    /*svg {
      width: 12px;
      height: 12px;
      fill: $vgc-orange;
    }*/
  }

  &__info {

    flex: 5;

    .headline, h1, h2, h3, h4, h5, h6 {
      @include heading-scale("sm", "3");
      margin: 0 0 ($spacing_sm / 2) 0;
      
      a {
        color: $vgc-black;

        &:active {
          color: $vgc-yellow;
        }
        &:hover,
        &:visited:hover {
          color: $vgc-orange;
        }
      }
    }

    .strapline {
      color: $vgc-darkgrey;
      font-size: 85%;
      line-height: 1.4;
    }
 
  }

}

// Post varints by content class

.vgc-post--standard {
  // ...this is the default
}

.vgc-post--featured {
  .vgc-post__icon {
    @include background-gradient("45deg", rgba($vgc-plum, 0.9), rgba($vgc-purple, 0.9));
  }
  .vgc-post__rank, .vgc-post__count {    
    @include background-gradient("45deg", rgba($vgc-purple, 0.9), rgba($vgc-plum, 0.9));
  }
  .vgc-post__ident {
    @include background-gradient("0deg", rgba($vgc-purple, 0.9), rgba($vgc-plum, 0.9));
  }
  .vgc-post__play {
    @include background-gradient("45deg", rgba($vgc-purple, 0.9), rgba($vgc-plum, 0.9));
  }
  .vgc-post__prefix {
    color: lighten(mix($vgc-purple, $vgc-plum, 50%), 15%);
    border-color: lighten(mix($vgc-purple, $vgc-plum, 50%), 15%);
    border-image-source: linear-gradient(0deg, rgba(lighten($vgc-purple, 15%), 0.95), rgba(lighten($vgc-plum, 15%), 0.95));
  }
  .vgc-post__info {
    .headline, h1, h2, h3, h4, h5, h6 {
      a {
        &:focus, &:hover {
          color: lighten(mix($vgc-purple, $vgc-plum, 50%), 15%) !important;
        }
      }
    }
  }
}

.vgc-post--blog {
  .vgc-post__icon {    
    @include background-gradient("45deg", rgba($vgc-cyan, 0.9), rgba($vgc-blue, 0.9));
  }
  .vgc-post__rank, .vgc-post__count {    
    @include background-gradient("45deg", rgba($vgc-blue, 0.9), rgba($vgc-cyan, 0.9));
  }
  .vgc-post__ident {
    @include background-gradient("0deg", rgba($vgc-blue, 0.9), rgba($vgc-cyan, 0.9));
  }
  .vgc-post__play {
    @include background-gradient("45deg", rgba($vgc-blue, 0.9), rgba($vgc-cyan, 0.9));
  }
  .vgc-post__prefix {
    color: $vgc-blue;
    border-color: $vgc-blue;
    border-image-source: linear-gradient(0deg, rgba($vgc-blue, 0.95), rgba($vgc-cyan, 0.95));
  }
  .vgc-post__info {
    .headline, h1, h2, h3, h4, h5, h6 {
      a {
        &:focus, &:hover {
          color: $vgc-blue !important;
        }
      }
    }
  }
}

.vgc-post--guide {
  .vgc-post__icon {
    @include background-gradient("45deg", rgba($vgc-red, 0.9), rgba($vgc-rust, 0.9));
  }
  .vgc-post__rank, .vgc-post__count {    
    @include background-gradient("45deg", rgba($vgc-red, 0.9), rgba($vgc-rust, 0.9));
  }
  .vgc-post__ident {
    @include background-gradient("0deg", rgba($vgc-rust, 0.9), rgba($vgc-red, 0.9));
  }
  .vgc-post__play {
    @include background-gradient("45deg", rgba($vgc-red, 0.9), rgba($vgc-rust, 0.9));
  }
  .vgc-post__prefix {
    color: $vgc-red;
    border-color: $vgc-red;
    border-image-source: linear-gradient(0deg, rgba($vgc-rust, 0.95), rgba($vgc-red, 0.95));
  }
  .vgc-post__info {    
    .headline, h1, h2, h3, h4, h5, h6 {
      a {
        &:focus, &:hover {
          color: $vgc-red !important;
        }
      }    
    }
  }
}

.vgc-post--object {
  .vgc-post__icon {    
    @include background-gradient("45deg", rgba($vgc-lightgrey, 0.95), rgba($vgc-grey, 0.95));
  }
  .vgc-post__rank, .vgc-post__count {    
    @include background-gradient("45deg", rgba($vgc-grey, 0.95), rgba($vgc-lightgrey, 0.95));
  }
  .vgc-post__ident {
    @include background-gradient("0deg", rgba($vgc-grey, 0.95), rgba($vgc-lightgrey, 0.95));
  }
  .vgc-post__play {
    @include background-gradient("45deg", rgba($vgc-grey, 0.95), rgba($vgc-lightgrey, 0.95));
  }
  .vgc-post__prefix {
//    color: $vgc-grey;
//    border-color: $vgc-grey;
//    border-image-source: linear-gradient(0deg, rgba($vgc-grey, 0.95), rgba($vgc-lightgrey, 0.95));
  }
  .vgc-post__info {    
    .headline, h1, h2, h3, h4, h5, h6 {
      a {
        &:focus, &:hover {
//          color: $vgc-grey !important;
        }
      }    
    }
  }
}

.vgc-post--sponsored {
}

.vgc-listing--promos,
.vgc-listing--flicker {

  // Hide the middle two until later. The first and last will always be
  // the important next/previous ones anyway...
  .vgc-post:nth-of-type(2),
  .vgc-post:nth-of-type(3) {
    display: none;
  }

}

// FIXME: highlights listings / posts with broken modifier classes applied
.vgc-listing-- {
  &::before {
    display: block;
    content: "FIXME: this .vgc-listing is missing a modifier class!";
  }
  outline: 2px dashed #f00;
  background: rgba(#f00, 0.25);
}
.vgc-post-- {
  &::before {
    display: block;
    content: "FIXME: this .vgc-post is missing a modifier class!";
  }
  outline: 2px dashed #f00;
  background: rgba(#f00, 0.25);
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {

  .vgc-post {
    
    &__info {

      .strapline {
        font-size: 100%;
      }

    }

    &__score {
      display: flex;
      transform: translateX(-50%) translateY(-50%) scale(0.8);
    }

  }
  

}

@include media-breakpoint-only(sm) {
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .vgc-listing {

    .vgc-post {
      margin-bottom: $spacing_md;

      &__icon {
        span {
          display: inline-block;
        }
      }
    }

  }

  .vgc-post {

    &__media {
      margin-right: $spacing_md;

      .bytes-image {
        padding-top: percentage(9 / 16);
      }

    }

    &__play {
      width: 44px;
      height: 48px;
    }

    &__info {

      .headline, h1, h2, h3, h4, h5, h6 {
        @include heading-scale("md", "3");
      }

    }

    &__score {
      display: flex;
      transform: translateX(-50%) translateY(-50%) scale(1);
    }

  }


  // Specific listings styles
  .vgc-listing--index {

    .vgc-post {      
    }

  }

  .vgc-listing--popular {

    .vgc-post {
      &:not(:last-child){
        margin-bottom: $spacing_sm;
      }
    }

  }

  
  .vgc-listing--reviews,
  .vgc-listing--review,
  .vgc-listing--features,
  .vgc-listing--opinion,
  .vgc-listing--guide {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  
    .vgc-post {
      display: block;
      width: 48%;

      &__media {
        margin-right: 0;
        margin-bottom: $spacing_sm;
      }

      &__score {
        transform: translateX(-50%) translateY(-50%) scale(1.5);
      }

    }  

  }
  
  .vgc-listing--promos,
  .vgc-listing--flicker {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  
    .vgc-post {
      display: block;
      width: 49%;

      &__media {
        margin-right: 0;
        margin-bottom: $spacing_sm;
      }

    }

  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  .vgc-listing {

    .vgc-post {
      margin-bottom: $spacing_md;
    }

  }

  .vgc-post {

    &__play {
      width: 58px;
      height: 64px;
    }

    &__info {

      .headline, h1, h2, h3, h4, h5, h6 {
        @include heading-scale("lg", "4");
      }

    }

  } 


  .vgc-listing--popular {

    .vgc-post {
      &:not(:last-child){
        margin-bottom: $spacing_sm;
      }
    }
    
  }  


  // Things in the sidebar need resetting back to not be so responsive
  // Might need moving up to (md) based on how this is presented at that breakpoint
  .vgc-layout__sidebar {

    .vgc-listing {

      .vgc-post {
        margin-bottom: $spacing_md;
      }

    }

    .vgc-post {

      &__media {
        margin-right: $spacing_sm;
        
        .bytes-image {
          padding-top: percentage(1 / 1);        
          @include corner-bit("5px");
        }
      }

      &__info {

        .headline, h1, h2, h3, h4, h5, h6 {
          @include heading-scale("lg", "5");
        }

        .meta {
//          @include visuallyhidden(); // because maybe?
        }

        .strapline {
          @include smallest-text();
          color: $vgc-darkgrey;
        }

      }

    }

  }


}

@include media-breakpoint-only(lg) {}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {

  .vgc-listing {

  }

  .vgc-post {
    
    &__media {
      margin-right: $spacing_lg;
    }

    &__info {

      .headline, h1, h2, h3, h4, h5, h6 {
        @include heading-scale("lg", "3");
      }

    }    

  }

  .vgc-listing--promos {
  
    .vgc-post {
      width: 24%;

      &__info {
        .headline, h1, h2, h3, h4, h5, h6 {
          @include heading-scale("lg", "4");
        }
      }

    }
  }

  .vgc-listing--promos,
  .vgc-listing--flicker {

    // Put the middle two back to pad things out again...
    .vgc-post:nth-of-type(2),
    .vgc-post:nth-of-type(3) {
      display: block;
    }

  }
  
}

@include media-breakpoint-only(xl) {}