/*************************************************************************************************/
/** Cookie Control *******************************************************************************/
/*************************************************************************************************/

// Borders, background and fonts for the two panels
#ccc-notify, #ccc-content {
  font-size: 14px !important;

  border-radius: 0 !important;

  background: #212529 !important; // set in themeing
  background-color: #212529 !important; // set in themeing
  box-shadow: rgba(0,0,0,0.5) 1px 1px 10px !important;
}

// Modal overlay background
#ccc-overlay {
  background: rgba(0,0,0,0.66) !important;
  backdrop-filter: blur(5px) !important;
}

// The initial "We use cookies" panel
#ccc-notify {

  .ccc-notify-text {
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 15px !important;
    }
    p {
      margin-bottom: 10px !important;
    }
  }

  .ccc-accept-button {
    background-color: #28a745 !important; // set in themeing
    border-color: #28a745 !important; // set in themeing
  }

  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    bottom: 0;
    background: $vgc-yellow;
    background-image: linear-gradient(0deg,#f47820,#feb823);
  }
}

// The main "Cookie Prefrence Center" panel
#ccc-content {

  #cc-panel {
    &:before {
      content: " ";
      display: block;
      position: absolute;
      top: -25px;
      left: -25px;
      width: 5px;
      bottom: 0;
      background: $vgc-yellow;
      background-image: linear-gradient(0deg,#f47820,#feb823);
    }
  }

  #ccc-save-settings {
    margin-bottom: 20px !important;
    background: $vgc-orange !important;
    border-color: $vgc-orange !important;
  }

}

// External links
#ccc-statement, #ccc-adtech {
  a {
    text-decoration: underline !important;
  }
  .ccc-svg-element {
    top: 0 !important;
  }
}
.third-party-cookie-link {
  text-decoration: underline !important;
}

// This mess of specificity is needed to stomp on !important from the plugin options
#ccc {
  #ccc-module {
    #ccc-optional-categories {
      .optional-cookie {
        input~.checkbox-toggle-toggle {
          background-color: #dd3333 !important;
        }
        input:checked~.checkbox-toggle-toggle {
          background-color: #28a745 !important;
        }
      }
    }
  }
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {

  #ccc-notify {
    max-height: 300px !important;
  }

}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  #ccc-notify, #ccc-content {
    font-size: 16px !important;
  }

  #ccc-notify {
    max-height: 350px !important;
  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {

}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/