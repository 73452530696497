/*************************************************************************************************/
/** Patch Notes Shortcode ************************************************************************/
/*************************************************************************************************/

.vgc-patchnotes {

  background: #f5f5f5;
  padding: $spacing_sm;
  margin-bottom: 1rem;

  font-size: 0.8em;

  margin-left: -10px;
  margin-right: -10px;

  p, li {
    // style 'em a special way?
  }

  ul:last-child,
  p:last-child {
    margin-bottom: 0;
  }

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .vgc-patchnotes {    
    margin-left: 0;
    margin-right: 0;
  } 

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/