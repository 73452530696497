/*************************************************************************************************/
/** Flexible Images ******************************************************************************/
/*************************************************************************************************/

// Can't see why all images in a figure shouldn't do this?!
figure {
  img {
    width: 100%;
    height: auto;
    display: block;
  }

  figcaption {
    text-align: right;
    font-style: italic;
    color: $vgc-grey;
  }

}

// Outermost container - usually a <div> or <header>
.bytes-image {
  width: 100%; 
  height: 0;
  margin: 0;
  padding: 0;
  padding-top: percentage(1 / 1); // This sets the aspect ratio for the entire thing
  display: inline-block;
  position: relative;
//  transform: rotate(-0.0000000001deg);
}

// Inner container - usually a <figure>
.bytes-image__container {
  position: absolute;
  margin: 0;
  padding: 0;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0; // remember negative margin if using blurs!

  overflow: hidden;
  background: $bytes-grey;
}

// The image itself - usually an <img> but can also be a <video>
.bytes-image__img {
  position: absolute;      
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%); // translateZ(-0.0000000001)

  width: auto;
  height: auto;

  max-height: none;
  max-width: none;

  min-height: 100% !important;
  min-width: 100% !important;

  &.lazyload {
    width: 100%; // fixes sizes=auto not working when in flex'd containers. WTF?
    opacity: 0;
  }
}
.bytes-image__img--crop-center {
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%); // translateZ(-0.0000000001)
}
.bytes-image__img--crop-top {
  top: 0;
  bottom: unset;
  transform: translateX(-50%); // translateZ(-0.0000000001);
}
.bytes-image__img--crop-bottom {
  bottom: 0;
  top: unset;
  transform: translateX(-50%); // translateZ(-0.0000000001);
}
.bytes-image__img--crop-left {
  left: 0;
  right: unset;
  transform: translateY(-50%); // translateZ(-0.0000000001);
}
.bytes-image__img--crop-right {
  right: 0;
  left: unset;
  transform: translateY(-50%); // translateZ(-0.0000000001);
}
.bytes-image__img--crop-topleft {
  top: 0;
  left: 0;
  bottom: unset;
  right: unset;
  transform: none;
}
.bytes-image__img--crop-topright {
  top: 0;
  right: 0;
  bottom: unset;
  left: unset;
  transform: none;
}
.bytes-image__img--crop-bottomleft {
  bottom: 0;
  left: 0;
  top: unset;
  right: unset;
  transform: none;
}
.bytes-image__img--crop-bottomright {
  bottom: 0;
  right: 0;
  top: unset;
  left: unset;
  transform: none;
}
.bytes-image__img--crop-fit {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;  
  transform: none;
  width: 100%;
  height: 100%;
}

// A container for overlaying things on top of the image
.bytes-image__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;  
  line-height: 100%;
}
.bytes-image__overlay--top {
  bottom: unset;
}
.bytes-image__overlay--bottom {
  top: unset;
}
.bytes-image__overlay--left {
  right: unset;
}
.bytes-image__overlay--right {
  left: unset;
}
.bytes-image__overlay--topleft {
  bottom: unset;
  right: unset;
}
.bytes-image__overlay--topright {
  bottom: unset;
  left: unset;
}
.bytes-image__overlay--bottomleft {
  top: unset;
  right: unset;
}
.bytes-image__overlay--bottomright {
  top: unset;
  left: unset;
}

.lazyload {
  opacity: 0; // Hide lazyload images until they're loaded
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {

  

}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {

  

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/