/*************************************************************************************************/
/* Pagination ************************************************************************************/
/*************************************************************************************************/

// Stream Pagination

.vgc-pagination {

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $spacing_sm;

  a, 
  span {
    display: block;
    padding: .5em 1em;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 1px;
    white-space: nowrap;
    @include small-text();
  }

  a {
    border: 1px solid $vgc-black;
    background-color: $vgc-black;
    color: $white;

    &:hover,
    &:visited:hover {
      color: $white;
      background-color: $vgc-orange;
      border: 1px solid $vgc-orange;
      text-decoration: none;
    }
  }

  span {
    border: 1px solid $vgc-lightgrey;
  }

  .dots {
    display: none;
  }

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .vgc-pagination {
    margin-top: $spacing_md;
  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  .vgc-pagination {
    margin-top: $spacing_lg;
  }

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/