/*************************************************************************************************/
/** Headings *************************************************************************************/
/*************************************************************************************************/

h1, h2, h3, h4, h5, h6, span.h1, span.h2, span.h3, span.h4, span.h5, span.h6 {
  @include brand-font;
  color: $vgc-black;
}


// Sidebar section headings
.vgc-layout__sidebar {

  section {
    > h3,
    > span.h3,
    > h4,
    > span.h4,
    > h5,
    > span.h5 {
      @include brand-font();
      display: block;

      @include heading-scale("lg","6");
      @include heading-block("small");
      margin-bottom: $spacing_sm;
    }

  }

}

.vgc-layout__promos {
  > h3,
  > span.h3,
  > h4,
  > span.h4,
  > h5,
  > span.h5 {
    @include brand-font();
    display: block;

    @include heading-scale("lg","6");
    @include heading-block("small");
    margin-bottom: $spacing_sm;
    a {
      text-decoration: none;
      color: unset;
    }
  }
  &--featured,
  &--opinion {    
    h3, span.h3, h4, span.h4, h5, span.h5 {
      @include background-gradient("45deg", $vgc-purple, $vgc-plum);
    }
  }
  &--object {
    h3, span.h3, h4, span.h4, h5, span.h5 {
      @include background-gradient("45deg", $vgc-grey, $vgc-lightgrey);
    } 
  }
  &--blog {
    h3, span.h3, h4, span.h4, h5, span.h5 {
      @include background-gradient("45deg", $vgc-blue, $vgc-cyan);
    }
  }
  &--guide {
    h3, span.h3, h4, span.h4, h5, span.h5 {
      @include background-gradient("45deg", $vgc-red, $vgc-rust);
    }
  }
}

.vgc-layout__pod {
  &-aside {
    h3,
    span.h3,
    h4,
    span.h4,
    h5,
    span.h5 {
      @include brand-font();
      display: block;

      @include heading-scale("lg","6");
      @include heading-block("small");
      margin-bottom: $spacing_sm;   
    }
  }
}

/*************************************************************************************************/
/**  Misc  ***************************************************************************************/
/*************************************************************************************************/

// this is also a mixin! de-dup at some point!
.meta {
  color: lighten($vgc-grey, 10%);
  //text-transform: capitalize;
  text-transform: uppercase;
  @include smallest-text();
  //font-weight: bold;
  margin: $spacing_sm 0 0 0;

  a {
    color: $vgc-grey;

    &:hover,
    &:visited:hover {
      color: $vgc-orange;
    }
  }

  .datestamp,
  .author a,
  .categories a,
  .comments {
    //text-transform: uppercase;
    font-weight: bold;
    color: $vgc-grey;
  }

  .comments {
    float: right;
  }

  .score {
    svg {
      width: 16px;
      height: 16px;
      margin-right: 2px;
      margin-bottom: 5px;

      path {
        fill: $vgc-purple;
      }
    }
  }
  .score-1 {
    svg:nth-child(n+2) {
      opacity: .33;

      path {
        fill: $vgc-grey;
      }
    }
  }
  .score-2 {
    svg:nth-child(n+3) {
      opacity: .33;
      
      path {
        fill: $vgc-grey;
      }
    }
  }
  .score-3 {
    svg:nth-child(n+4) {
      opacity: .33;
      
      path {
        fill: $vgc-grey;
      }
    }
  }
  .score-4 {
    svg:nth-child(n+5) {
      opacity: .33;
      
      path {
        fill: $vgc-grey;
      }
    }
  }
  .score-5 {
    svg:nth-child(n+6) {
      opacity: .33;
      
      path {
        fill: $vgc-grey;
      }
    }
  }

}


/*************************************************************************************************/
/**  Links  **************************************************************************************/
/*************************************************************************************************/

a {
  transition: all .25s ease-out;  
  &:focus, &:hover {
    transition: all .05s ease-out;
    text-decoration: none;
    // if I could write-protect this bit, I would :)
  }
}

// Luc - these are applied to all in-article links to try and give
//       some hint as to what each link is / how it will behave

// see https://dev.videogameschronicle.com/brendan-greene-doesnt-want-to-make-pubg-2/
// for an extreme example of link-fest

a.link-relationship,
a.link-internal,
a.link-external {
  padding-bottom: 2px;
  border-bottom: 2px solid $vgc-lightgrey;
}

// internal links, added by <prod> tags manually or the relationships
// system automatically. goes to a game/company/etc homepage
a.link-relationship {
  color: unset;
  //border-color: rgba($vgc-lightgrey, 0.66);
  border-bottom: 2px dotted rgba($vgc-lightgrey, 0.66);
  &:focus, &:hover {    
    color: $vgc-orange;
    border-bottom: 2px solid $vgc-yellow;    
  }
}

// internal link, goes to VGC (they've usually linked another story)
a.link-internal {  
  color: unset;
//  font-weight: bold; // Binnsy suggestion
  border-bottom: 2px dotted rgba($vgc-orange, 0.66);
  &:focus, &:hover {    
    color: $vgc-orange;
    border-bottom: 2px solid $vgc-yellow;
  }
}

// external link, not VGC, usually forced to target=_blank
a.link-external {  
  color: unset;
//  font-weight: bold; // Binnsy suggestion
  border-bottom: 2px solid rgba($vgc-lightgrey, 0.66);
  &:focus, &:hover {    
    color: $vgc-orange;
    border-bottom: 2px solid $vgc-yellow;
  }
}

.post--featured,
.post--opinion {
  a.link-relationship,
  a.link-external {    
    &:focus, &:hover {    
      color: $vgc-purple;
      border-bottom: 2px solid $vgc-plum;
    }
  }
  a.link-internal {  
    border-bottom: 2px dotted rgba($vgc-purple, 0.66);
    &:focus, &:hover {    
      color: $vgc-purple;
      border-bottom: 2px solid $vgc-plum;
    }
  }  
}

.post--blog {
  a.link-relationship,
  a.link-external {    
    &:focus, &:hover {    
      color: $vgc-blue;
      border-bottom: 2px solid $vgc-cyan;
    }
  }
  a.link-internal {  
    border-bottom: 2px dotted rgba($vgc-blue, 0.66);
    &:focus, &:hover {    
      color: $vgc-blue;
      border-bottom: 2px solid $vgc-cyan;
    }
  }  
}

.post--guide {
  a.link-relationship,
  a.link-external {    
    &:focus, &:hover {    
      color: $vgc-red;
      border-bottom: 2px solid $vgc-rust;
    }
  }
  a.link-internal {  
    border-bottom: 2px dotted rgba($vgc-red, 0.66);
    &:focus, &:hover {    
      color: $vgc-red;
      border-bottom: 2px solid $vgc-rust;
    }
  }  
}
