/*************************************************************************************************/
/** Product Shortcode ****************************************************************************/
/*************************************************************************************************/

.vgc-products {

  background: #f5f5f5;
  padding: $spacing_sm;
  
  margin-top: 2rem;
  margin-bottom: 2rem;

  margin-left: -10px;
  margin-right: -10px;

  font-size: unset;
  line-height: unset;

  .vgc-products__title {
    font-size: 1rem !important;    
    padding-bottom: 6px !important;
    margin-bottom: 1rem !important;
    text-transform: uppercase;
    position: relative;      
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(90deg,#f47820,#feb823);
    }
  }

}
.vgc-products + .vgc-products {
  border-top: 2px solid #fff;
  margin-top: -2rem;
  padding-top: 15px;
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .vgc-products {
    margin-left: 0;
    margin-right: 0;    
  } 

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/