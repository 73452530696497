// Stuff to make media work better

// The corner bit
@mixin corner-bit ( $bitsize: "10px" ) {
  position: relative;
  
  &::after {
    content: "";
    position: absolute;
    height: 0px;
    border-right: unquote($bitsize) solid $vgc-white;
    border-bottom: unquote($bitsize) solid $vgc-white;
    border-top: unquote($bitsize) solid transparent;
    border-left: unquote($bitsize) solid transparent; 

    bottom: 0;
    right: 0;

  }

}


// A left hand bar thingy for an image
@mixin image-flourish ( $flourishsize: "10px" ) {

  &::before {
    content: "";
    position: absolute;
    height: 50%;
    width: unquote($flourishsize);
    @include background-gradient("0deg", $vgc-orange, $vgc-yellow);
    bottom: 0;
    left: 0;
    z-index: 10;
  }

}


// VGC background gradient
@mixin background-gradient( $deg: "90deg", $start: $vgc-orange, $end: $vgc-yellow) {
  background-image: linear-gradient(unquote($deg), $start 0%, $end 100%);
}