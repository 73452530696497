.vgc-newsflash {
  display: flex;
  margin-bottom: $spacing_md;

  a {
    color: $vgc-white;
    font-weight: bold;
  }

  &__brand {
    display: flex;
    background-color: $vgc-orange;
    padding: 10px 15px;

    svg {
      height: 24px;
      width: auto;
      display: block;
      margin: 0;
      fill: #fff;
    }
  }

  &__content {

    font-size: 0.85em;

    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    //padding-left: $spacing_sm;
    @include background-gradient("45deg", $vgc-orange, $vgc-yellow);
    @include corner-bit("5px");

    ul {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-wrap: wrap;
      overflow: hidden;
      list-style-type: none;
      list-style-image: none;
      height: 44px;
      margin: 0;
      padding: 0;

      li {
        line-height: 42px; // Lato has a tall cap-height, so need to be weird here + padding below.
        padding: 0 $spacing_sm;

        a {
          padding: 0 5px 2px 5px;
          &:hover {
            background-color: rgba($vgc-white, .2);
          }
        }

        &::before {
          display: none;
        }
      }
    }


    &-link {
      font-family: Kelson, sans-serif;
      font-weight: 700;
      font-size: 85%;
      line-height: 1.3;
      text-transform: uppercase;
      display: none;
      line-height: 42px; // Lato has a tall cap-height, so need to be weird here.
      margin-left: auto;
      margin-right: $spacing_md;
    }

  }

}


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {
  
  .vgc-newsflash {
    &__content { 
      justify-content: space-between;     
      &-link {
        display: block;        
      }
    }
  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {
  
  .vgc-newsflash {
    &__content { 
      font-size: 1em;
    }
  }

}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/
