/*************************************************************************************************/
/** Quotes ***************************************************************************************/
/*************************************************************************************************/

blockquote {

  @include brand-font();
  @include heading-scale("sm", "2");
  line-height: 1.3;
  color: $vgc-purple;

  p {
    margin: 0;
  }


}

.post--standard {
  blockquote {
    
    color: $vgc-orange;   

  }
}

.post--guide {
  blockquote {
    
    color: $vgc-red;   

  } 
}

.post--blog {
  blockquote {
    
    color: $vgc-blue;

  }
}


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  blockquote {

    @include heading-scale("md", "2");

  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  blockquote {
    padding: $spacing_lg ($spacing_lg * 2);
    @include heading-scale("lg", "3");
    line-height: 1.3;
    background: -webkit-linear-gradient(45deg, $vgc-purple 0%, $vgc-plum 50%);
    background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
  }

  .post--standard {
    blockquote {      
      background: -webkit-linear-gradient(45deg, $vgc-orange 0%, $vgc-yellow 100%);
      background-clip: text;
      -webkit-background-clip: text;
      text-fill-color: transparent;
      -webkit-text-fill-color: transparent;      
    }
  }

  .post--guide {
    blockquote {      
      background: -webkit-linear-gradient(45deg, $vgc-red 0%, $vgc-rust 100%);
      background-clip: text;
      -webkit-background-clip: text;
      text-fill-color: transparent;
      -webkit-text-fill-color: transparent;      
    }
  }

  .post--blog {  
    blockquote {      
      background: -webkit-linear-gradient(45deg, $vgc-blue 0%, $vgc-cyan 100%);
      background-clip: text;
      -webkit-background-clip: text;
      text-fill-color: transparent;
      -webkit-text-fill-color: transparent;      
    }
  }

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/