// Object meata stuff
.vgc-object__header {

  &-image {
    width: 160px;
    margin-bottom: $spacing_sm;
    line-height: 0;

    figure {
      background-color: $vgc-lightestgrey;

      // when there's a fallback icon instead of an actual image
      svg {
        margin: $spacing_sm;

        path {
          fill: $vgc-orange;
        }
      }
    }

    .bytes-image {
      padding-top: percentage(1 / 1);
    }
  }

  &-details {
    h1 {
      @include heading-scale("sm","1");
      @include heading-underlined("small");
      text-transform: uppercase;
      color: $vgc-black;
    }

    > p {
      @include font-size(18);
      @include brand-font("400");
    }

  } 

}


.type-companies {
  .vgc-object__header {

    &-image {
      .bytes-image {
        display: block;

        &__container {
          background: none;      
        }
        &__img {
          min-height: auto;
          height: auto;
          width: 100%;
          left: auto;
          top: auto;
          transform: none;
          position: relative;
        }

      }
    }

  }
}



/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/


@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/


@include media-breakpoint-up(md) {

  .vgc-object__header {
    display: flex;
    align-items: flex-start;

    padding-bottom: $spacing_md;

    &-image {
      width: 200px;
      flex: 0 0 200px;
      margin-right: $spacing_lg;
      margin-bottom: 0;
    }

    &-details {
      h1 {
        @include heading-scale("md","1");
        @include heading-underlined("normal");
      }
      p {
        @include font-size(20);
      }
    }

  }

}

@include media-breakpoint-only(md) { }


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/


@include media-breakpoint-up(lg) {

  .vgc-object__header {

    &-details {
      h1 {
        @include heading-scale("lg","1");
        @include heading-underlined("large");
      }
      p {
        @include font-size(22);
      }
    }

  }

}

@include media-breakpoint-only(lg) { }


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/


@include media-breakpoint-up(xl) {
}

@include media-breakpoint-only(xl) { }