// Better font-size mixin from https://coderwall.com/p/ml9zxg/sass-mixin-for-rem-font-sizing-line-height-with-px-fallback
@mixin font-size($fontsize: 16, $lineheight: $fontsize * 1.5) {
  font-size: ($fontsize) + px;
  line-height: ($lineheight) + px;
  font-size: ($fontsize / 10) + rem;
  line-height: ($lineheight / 10) + rem;
}



// Used for headings, headlines, etc
@mixin brand-font ( $weight: "700" ) {
  font-family: 'Kelson', sans-serif;
  font-weight: unquote($weight);
}



// Simple font size changes
@mixin largest-text(){
  font-size: 150%;
}

@mixin large-text(){
  font-size: 120%;
}

@mixin reset-text(){
  font-size: 100%;
  line-height: inherit; 
}

@mixin small-text(){
  font-size: 85%;
  line-height: 1.3;
}

@mixin smallest-text(){
  font-size: 75%;
  line-height: 1.2;
}



// Type scales
@mixin heading-scale ( $breakpoint, $size ) {

  @if( $size == "0" ){ 
    
    @if ( $breakpoint == "sm") {
      @include font-size(36,42);
    }
    @else if ( $breakpoint == "md") {
      @include font-size(52,58);
    }
    @else if ( $breakpoint == "lg") {
      @include font-size(78,84);
    }

  } 

  @if( $size == "1" ){ 
    
    @if ( $breakpoint == "sm") {
      @include font-size(26,30);
    }
    @else if ( $breakpoint == "md") {
      @include font-size(36,42);
    }
    @else if ( $breakpoint == "lg") {
      @include font-size(52,58);
    }

  } 

  @if( $size == "2" ){ 
    
    @if ( $breakpoint == "sm") {
      @include font-size(22,26);
    }
    @else if ( $breakpoint == "md") {
      @include font-size(30,34);
    }
    @else if ( $breakpoint == "lg") {
      @include font-size(46,50);
    }

  } 

  @if( $size == "3" ){ 
    
    @if ( $breakpoint == "sm") {
      @include font-size(18,24);
    }
    @else if ( $breakpoint == "md") {
      @include font-size(24,28);
    }
    @else if ( $breakpoint == "lg") {
      @include font-size(32,36);
    }

  } 

  @if( $size == "4" ){ 
    
    @if ( $breakpoint == "sm") {
      @include font-size(16,20);
    }
    @else if ( $breakpoint == "md") {
      @include font-size(20,24);
    }
    @else if ( $breakpoint == "lg") {
      @include font-size(24,28);
    }

  } 

  @if( $size == "5" ){ 
    
    @if ( $breakpoint == "sm") {
      @include font-size(14,20);
    }
    @else if ( $breakpoint == "md") {
      @include font-size(15,21);
    }
    @else if ( $breakpoint == "lg") {
      @include font-size(16,23);
    }

  } 

  @if( $size == "6" ){ 
    
    @if ( $breakpoint == "sm") {
      @include font-size(10,12);
    }
    @else if ( $breakpoint == "md") {
      @include font-size(11,14);
    }
    @else if ( $breakpoint == "lg") {
      @include font-size(12,16);
    }

  } 
}



// Underlines styles
@mixin heading-underlined( $size: "normal", $color: "orange" ) {

  position: relative;
  text-transform: uppercase;

  @if( $size == "small" ){
    padding-bottom: 4px;
  }
  @if( $size == "normal" ){
    padding-bottom: 8px;
  }
  @if( $size == "large" ){
    padding-bottom: 15px;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;

    @if( $size == "small" ){
      height: 2px;
    }
    @if( $size == "normal" ){
      height: 4px;
    }
    @if( $size == "large" ){
      height: 6px; 
    }

    bottom: 0;
    left: 0;

    @if( $color == "orange" ){
      @include background-gradient();
    }
    @if( $color == "purple" ){
      @include background-gradient("90deg", $vgc-purple, $vgc-plum);
    }
    @if( $color == "blue" ){
      @include background-gradient("90deg", $vgc-blue, $vgc-cyan);
    }
    @if( $color == "red" ){
      @include background-gradient("90deg", $vgc-red, $vgc-rust);
    }

  }

}



// Block styles
@mixin heading-block( $size: "normal" ) {
  text-transform: uppercase;
  line-height: 1;
  color: $vgc-white;
  @include background-gradient();

  @if( $size == "small" ){
    padding: 4px;
    @include corner-bit("4px");
  }
  @if( $size == "normal" ){
    padding: 8px;
    @include corner-bit("8px");
  }
  @if( $size == "large" ){
    padding: 16px;
    @include corner-bit("16px");
  }
}


// Meta
@mixin meta() {
  color: $vgc-grey;
  text-transform: uppercase;
  @include smallest-text();
  font-weight: bold;
  margin: $spacing_sm 0 0 0;

  a {
    color: $vgc-grey;

    &:hover,
    &:visited:hover {
      color: $vgc-orange;
    }
  }
}