/*************************************************************************************************/
/** Adverts **************************************************************************************/
/*************************************************************************************************/

// Use for ads that need to be centered within their parent
@mixin advert-horizontal {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

// Use for ads that shouldn't resize, even if smaller sizes are served into them
@mixin advert-structural {  
  display: flex;
  justify-content: center;
  align-items: center;
}

.advert {
  // background: $bytes-grey;
  // background: none;
  background: rgba($vgc-lightgrey, 0.5);

  position: relative;
  &::after {
    display: none !important;    

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: "ADVERT";
    padding: 5px;
    font-size: 10px;
    font-family: monospace;
    text-align: center;
    color: rgba(#fff, 0.88);    
    background: rgba(#000, 0.5);
  }

  // Sticky inner ads for all
  > div {
    position: sticky;
    top: 0;
  }
}

body.env-testing {
  .advert {
    box-shadow: inset 0 0 2px #000, 0 0 2px #000;
    &::after {
      display: block !important;
      background: rgba(#000, 0.5);
    }
  }
  .advert--loading,
  .adm-ad-loading {
    box-shadow: inset 0 0 2px #f0f, 0 0 2px #f0f;
    &::after {
      display: block !important;
      background: rgba(#f0f, 0.5);
    }
  }
  .advert--filled,
  .adm-ad-rendered {
    box-shadow: inset 0 0 2px #00C000, 0 0 2px #00C000;
    &::after {
      display: block !important;
      background: rgba(#00C000, 0.5);
    }
  }
  .advert--empty {
    box-shadow: inset 0 0 2px #f00, 0 0 2px #f00;
    &::after {
      display: block !important;
      //display: none !important;
      background: rgba(#f00, 0.5);
    }
  }
}

// Podcast promo
.vgc-podcast-promo {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Skin container
.advert__skin {
  position: relative;
  overflow: clip;

//  background: $vgc-grey; //#848484;

  padding-top: 10px;
  padding-bottom: 10px;
}

.advert--legacy {
  &::after {
    opacity: 0;
  }
  padding: 20px;
  border: 1px dashed #f0f;
  font-size: 10px;
  font-family: monospace;
  text-align: center;
  color: #f0f;
}

// Unused

.sticky-container {
  //display: none;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  padding: 15px 15px 0px 15px;
  border-radius: 10px 10px 0 0;
  background: rgba(192,192,192,0.66);
  backdrop-filter: blur(5px);
  z-index: 999999;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.33);
}

// Publisher Collective

.advert--leaderboard {
  @include advert-horizontal();
  @include advert-structural();

  //background: none;

  width: 970px;
  height: 250px;

  margin-bottom: 15px;

  &::after {
    content: "AD #" attr(id) " - 970x250";
  }

  display: none;
}
.house--nineseventy {
  @include advert-horizontal();
  @include advert-structural();

  //background: none;

  width: 970px;
  height: 250px;

  margin-bottom: 15px;
  clip-path: polygon(45px 0, 100% 0, 100% 200px, 920px 100%, 0 100%, 0 45px);

  display: none;
}

.advert--horizontal-adbreak {
  @include advert-horizontal();
  @include advert-structural();

  //background: none;

  width: 970px;
  height: 250px;

  margin-bottom: 15px;

  &::after {
    content: "AD #" attr(id) " - 970x250";
  }

  display: none;
}

.advert--stickymobile {
  &::after {
    content: "Sticky AD #" attr(id);
  }
}
.advert--stickydesktop {
  &::after {
    content: "Sticky AD #" attr(id);
  }
}

.advert--skin {
  &::after {
    content: "Skin AD #" attr(id);
  }
  display: none;
}

.advert--player {
  &::after {
    content: "Player AD #" attr(id);
  }
//  margin-bottom: 1rem; // Not needed if within an .adcontainer
}

.advert--mpu1,
.advert--mpu2,
.advert--mpu3 {
  @include advert-structural();

  //background: none;

  min-width: 336px;
  min-height: 600px;

  &::after {
    content: "AD #" attr(id) " - 336x600+";
  }

  display: none;
}
.advert--mpu1 {
  margin-bottom: 15px;
}
.advert--mpu2,
.advert--mpu3 {
  position: sticky;
  top: 0;
}
.house--sixhundred {
  @include advert-structural();

  width: 336px;
  height: 600px;

  position: sticky;
  top: 30px;

  margin-top: 20px;

  video {
    margin-left: auto;
    margin-right: auto;
    clip-path: polygon(45px 0, 100% 0, 100% 555px, 255px 100%, 0 100%, 0 45px);
  }

  display: none;
}

.advert--sky1 {
  @include advert-structural();

  //background: none;

  min-width: 160px;
  min-height: 600px;

  &::after {
    content: "AD #" attr(id) " - 160x600+";
  }

  position: sticky;
  top: 0;

  display: none;
}

.advert--auto-mobile-mpu,
.advert--inline-mobile-mpu {
  @include advert-structural();

  //background: none;

//  width: 336px;
//  height: 600px;

  min-width: 300px;
  min-height: 300px;

  &::after {
    content: "AD #" attr(id) " - 300x300+";
  }
}
.advert--auto-desktop-mpu,
.advert--inline-desktop-mpu {
  @include advert-structural();

  //background: none;

  min-width: 336px;
  min-height: 280px;

  &::after {
    content: "AD #" attr(id) " - 336x280+";
  }

  display: none;
}

// Containers

.adcontainer {
  border-top: 1px solid rgba($vgc-lightgrey, 0.5);
  border-bottom: 1px solid rgba($vgc-lightgrey, 0.5);

  padding-top: 1rem;
  padding-bottom: 1rem;

  margin-bottom: 1rem;

  clear: both;
  position: relative;

  margin-left: -10px;
  margin-right: -10px;

  &::after {
    display: block;

    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    padding: 5px 15px;
    background: #fff;

    content: "Advertisement";

    color: $vgc-lightgrey;
    text-transform: uppercase;
    font-size: 50%;
    font-weight: bold;
  }
}
.adcontainer--mobile {

}
.adcontainer--desktop {
  display: none;
}

// Specific cases for stickyness
body.home {
  #nn_mpu1_wrap,
  #nn_mpu1,
  #nn_mpu2 {
    position: sticky;
    top: 0;
  }
}

#nn_mpu1_wrap {
  margin-bottom: $spacing_md;
}

// Hide containers which have served empty
.adcontainer:has(.adm-ad-empty) {
  display: none !important;
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {

  .advert__skin {
    padding-top: 15px;
    padding-bottom: 15px;
  }

}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {



}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  // Hide smaller
  .adcontainer--mobile {
    display: none;
  }
  .sticky-container--mobile {
    display: none;
  }
  .advert--auto-mobile-mpu,
  .advert--inline-mobile-mpu {
    display: none;
  }

  // Show LG
  .adcontainer {
    margin-left: 0;
    margin-right: 0;
  }
  .adcontainer--desktop {
    display: block;
  }
  .advert--leaderboard {
    display: flex; // because advert-structural !
  }
  .advert--horizontal-adbreak {
    display: flex; // because advert-structural !
  }
  .advert--skin {
    display: block;
  }
  .advert--mpu1,
  .advert--mpu2,
  .advert--mpu3 {
    display: block;
  }
  .advert--sky1 {
    display: block;
  }
  .sticky-container--desktop {
    display: block;
  }
  .advert--auto-desktop-mpu,
  .advert--inline-desktop-mpu {
    display: block;
  }

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) { 

  // Hide smaller

  // Show XL

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/