/*************************************************************************************************/
/** Colour Palette *******************************************************************************/
/*************************************************************************************************/

// VGC
$vgc-orange:       #f47820;
$vgc-yellow:       #feb823;

// Features
$vgc-purple:       #211c5b;
$vgc-plum:         #511e51;

// Blogs
$vgc-blue:         #205cf4;
$vgc-cyan:         #209df4;

// Guides
$vgc-red:          #ff0000;
$vgc-rust:         #c60000;

$vgc-grey:         #999999;
$vgc-lightgrey:    #dddddd;

$vgc-black:        #000000;
$vgc-white:        #ffffff;

$vgc-lightestgrey: #eeeeee;
$vgc-darkgrey:     #222222;

// 3rd parties
$twitter:          #1da1f2;
$facebook:         #3b5998;
$reddit:           #ff4500;

// 44 Bytes
$bytes-yellow:     #ffc800;
$bytes-orange:     #ff880b;
$bytes-red:        #ff4717;
$bytes-green:      #2AAD62;
$bytes-blue:       #3DA5D9;
$bytes-black:      #333333;
$bytes-grey:       #f9f9f9;

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/