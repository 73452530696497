/*************************************************************************************************/
/** Utility Mixins *******************************************************************************/
/*************************************************************************************************/

@mixin clearfix() {
  &::before, &::after {
    content: " ";
    display: table;
  }
  &::after {
    clear: both;
  }
}

// Visually hidden
@mixin visuallyhidden() {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@mixin reset-spacing() {  
  padding: 0;
  margin: 0;
}

@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin list-plain() {  
  padding: 0;
  margin: 0;
  list-style-type: none;
  list-style-image: none;
}

@mixin list-inline() {
  display: flex;
  flex-wrap: wrap;
  
  li:not(:last-of-type) {
    margin-right: .75em;
  }
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/