// Share this block and overrides
.vgc-socialshare {

  display: flex;
  opacity: 0;
  line-height: 1;
  min-height: 38px; // CLS fix

  .st-custom-button {
    border: 1px solid $vgc-grey;
    border-radius: 50px;
    padding: $spacing_sm;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: $spacing_sm;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    display: block;

    path {
      fill: $vgc-orange;
    }
  }

  .st-custom-button:hover {
    svg {
      path {
        fill: $vgc-white;
      }
    }
  }

  .st-custom-button[data-network='twitter']:hover {
    background-color: $twitter;
    border-color: $twitter;
  }

  .st-custom-button[data-network='facebook']:hover {
    background-color: $facebook;
    border-color: $facebook;
  }

  .st-custom-button[data-network='reddit']:hover {
    background-color: $reddit;
    border-color: $reddit;
  }

  .st-custom-button[data-network='email']:hover {
    background-color: $vgc-orange;
    border-color: $vgc-orange;
  }


}

.vgc-socialshare--show {
  opacity: 1 !important;
}

// THe aside footer sharing thing
.vgc-social {

  ul {
    @include list-plain();
    line-height: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      margin-bottom: $spacing_sm / 2;
      width: 100%;
      background: $vgc-lightgrey;

      a {
        display: flex;
        align-items: center;
        font-weight: bold;

        &:hover {
          figure {
            background-color: $vgc-orange;
          }
        }
      }
    }
    li:last-of-type {
      margin-bottom: 0;
    }
  }

  figure {
    padding: $spacing_sm / 2;
    background-color: $vgc-grey;
    margin: 0 $spacing_sm 0 0;
    flex: 20px;
    flex-grow: 0;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: $vgc-white;
      }
    }
  }

  span {
    @include smallest-text();
    text-transform: uppercase;
    text-align: center;
  }

}
.vgc-social--homepage {
  margin-top: 1em;
}

/*************************************************************************************************/
@include media-breakpoint-up(lg) {

  .vgc-social {
  }

}

@include media-breakpoint-only(lg) { }
/*************************************************************************************************/
@include media-breakpoint-up(xl) {

  .vgc-social {
    ul {
      li {
      
//      width: 46%;
      }
    }
  }

}

@include media-breakpoint-only(xl) { }
/*************************************************************************************************/