// Re-usable "in brief" bits
.ident-inbrief {
  padding: 5px 5px 5px 5px;

  background: none; // fixes sub-pixel black line
  @include background-gradient();
  @include background-gradient("45deg", rgba($vgc-grey, 0.95), rgba($vgc-lightgrey, 0.95));

  @include corner-bit();

  margin: 5px 0 0 0;
  padding-left: 12px;

  color: #fff;
  text-transform: uppercase;
  @include smallest-text();
  font-weight: bold;

  position: relative;

  &:before {
    display: block;
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 7px;
    @include background-gradient("0deg", rgba($vgc-orange, 0.9), rgba($vgc-yellow, 0.9));

    box-shadow: -2px 0px 2px -1px rgba(0,0,0,0.33), 2px 0px 2px -1px rgba(0,0,0,0.33);

  }

  svg {
    width: 12px;
    height: 12px;
    fill: #fff;
    position: relative;
    top: 1px;
    margin-right: 3px;
    float: left;
  }

  span {
    display: block;
    overflow: hidden;
  }

}