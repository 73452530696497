/*************************************************************************************************/
/** Author Excerpt *******************************************************************************/
/*************************************************************************************************/

// Author
.author-excerpt {
  display: flex;
  align-items: flex-start;
  border-top: 1px solid $vgc-lightgrey;
  padding-top: $spacing_sm;
  margin-top: $spacing_sm;
  margin-bottom: $spacing_sm;

  figure {
    flex: 0 0 50px;
    margin-right: $spacing_sm;
    margin-bottom: 0;
    position: relative;
    @include corner-bit("5px");
    background: linear-gradient(0deg, $vgc-grey, $vgc-lightgrey);
  }

  &__details {
    padding-bottom: $spacing_sm;

    p {
      margin: 0;
    }

  }

  &__headings {

    h4, span.h4 {
      @include brand-font();
      display: block;

      @include heading-scale("sm", "4");
      white-space: nowrap;
      margin: 0;

      a {
        color: $vgc-black;
      }
    }

    h5, span.h5 {
      @include brand-font();
      display: block;

      @include heading-scale("sm", "5");
      text-transform: uppercase;
      color: $vgc-orange;
      margin: 0;
    }

  }

  &__social {
    margin-bottom:$spacing_sm;

    .twitter {
      color: $vgc-grey;
      @include small-text();
    }

  }

  &__description {
    
  }

}

// When this is a header to an index;
.page-header {
  .author-excerpt {

    border-top: 0;
    padding-top: 0;
    margin-top: 0;
    @include heading-underlined("small");

    &__headings {
      h1 {
        @include heading-scale("sm", "2");
        margin: 0;
        padding: 0;
        text-transform: uppercase;

        &::after {
          display: none;
        }
      } 
    }

    &__description {
      text-transform: none;
    }

  }
}


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .author-excerpt {
    padding-top: $spacing_md;
    margin-top: $spacing_md;
    margin-bottom: $spacing_md;

    figure {
      flex: 0 0 100px;
      margin-right: $spacing_md;
    }

    &__headings {

      h4, span.h4 {
        @include heading-scale("md", "5");
      }

      h5, span.h5 {
        @include heading-scale("md", "6");
      }

    }

  }

  // When this is a header to an index;
  .page-header {
    .author-excerpt {

      border-top: 0;
      padding-top: 0;
      margin-top: 0;
      @include heading-underlined("medium");

      &__headings {
        h1 {
          @include heading-scale("md", "2");
          margin: 0;
          padding: 0;
        }
      }

    }
  }  

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  .author-excerpt {
    padding-top: $spacing_lg;
    margin-top: $spacing_lg;
    margin-bottom: $spacing_lg;

    figure {
      margin-right: $spacing_lg;
    }

    &__headings {

      h4, span.h4 {
        @include heading-scale("lg", "4");
      }

      h5, span.h5 {
        @include heading-scale("lg", "5");
      }
      
    }
  }

  // When this is a header to an index;
  .page-header {
    .author-excerpt {

      border-top: 0;
      padding-top: 0;
      margin-top: 0;
      @include heading-underlined("large");

      &__headings {
        h1 {
          @include heading-scale("lg", "1");
          margin: 0;
          padding: 0;
        }
      }

    }
  }    

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/