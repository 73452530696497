    .video-container,
    .entry-content-asset--video {
      position: relative; 
      padding-bottom: 56.25%;
      overflow: hidden;
      max-width: 100%;
      height: auto;

      iframe,
      object,
      embed { 
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }