/*************************************************************************************************/
/** Updated SCSS for Comments, Profile Section, and Related Elements ******************************/
/*************************************************************************************************/

/* Button Base Styles */
%button-base {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 0.2s ease;
  white-space: nowrap;
  cursor: pointer;
  color: $vgc-white;
  border: none;
}

.button-small {
  @extend %button-base;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  background: $vgc-purple;

  &:hover {
    background: $vgc-plum;
  }
}

.button-normal {
  @extend %button-base;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  background: $vgc-orange;

  &:hover {
    background: $vgc-yellow;
  }
}

.comments .comment-list .comment .reply .comment-reply-link, .comments .comment-list .comment .comment-metadata .edit-link a, .comments .comment-list .comment .reply .comment-reply-login  {
  padding: 5px 12px;
  font-size: .775rem;
  line-height: 1.1;
}


form {
  label {
    @include brand-font();
    text-transform: uppercase;
    @include small-text();
  }

  input[type="text"],
  input[type="search"],
  input[type="number"],
  input[type="email"],
  textarea {
    border: 2px solid $vgc-grey;
    padding: ($spacing_sm / 2) $spacing_sm;
  }

  button {
    border: 0;
    padding: ($spacing_sm / 2) $spacing_lg;
    @include background-gradient();
    @include corner-bit("4px");

    img {
      width: 16px;
      height: 16px;
      display: block;
    }

    svg {
      width: 16px;
      height: 16px;
      fill: currentColor;
    }
  }
}

/* Other Elements */
.vgc-comments__indicator {
  padding: 7px;
  padding-top: 9px;

  img, svg {
    fill: $vgc-orange;
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  a {
    color: $vgc-orange;
    @include brand-font();
  }
}

.community-notice {
  border-top: 1px solid $vgc-lightgrey;
  border-bottom: 1px solid $vgc-lightgrey;
  padding: $spacing_md;
  margin: $spacing_md 0;
  @include small-text();
  color: $vgc-grey;
}

.name-setup-notice,
.comments-notice {
  position: relative;
  overflow: hidden;

  .notice-image {
    display: none;
    float: right;
    margin-left: 15px;
    max-width: 150px;
    height: auto;
  }
}

/* Comments */
.comments {
  

  .comment-list {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;

    .comment {
      margin-bottom: $spacing_md;
      background: #f5f5f5;
      list-style-type: none;

      .comment-body {
        padding: $spacing_sm $spacing_md;
      }

      .comment-author {
        display: flex;
        align-items: flex-start;
        gap: 0.75rem;
        margin-bottom: 0.25rem;

        img.avatar {
          width: 48px;
          height: 48px;
          border-radius: 4px;
        }

        .fn {
          font-size: 1rem;
          font-weight: 600;
          color: $vgc-darkgrey;
        }

        .says {
          display: none;
        }
      }

      .comment-metadata {
        font-size: 0.875rem;
        color: $vgc-grey;
        margin-left: calc(48px + 0.75rem);
        display: flex;
        align-items: center;
        gap: 0.75rem;

        a {
          color: inherit;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .edit-link {
          a {
            @extend .button-small;
            color: $vgc-white;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .comment-content {
        margin-top: $spacing_sm;
        margin-left: calc(48px + 0.75rem);
        color: $vgc-darkgrey;
        line-height: 1.6;
        font-size: 1rem;

        p {
          margin: 0;
        }
      }

      .reply {
        margin-top: $spacing_sm;
        margin-left: calc(48px + 0.75rem);

        .comment-reply-link, .comment-reply-login {
          @extend .button-normal;
          color: $vgc-white;
        }
      }
    }

    // Nested Comments
    .children {
      list-style: none;
      padding-left: $spacing_md;
      margin: $spacing_sm 0 0 0;
      article {
        border-left: solid 1px $vgc-lightgrey;
      }

      .comment {
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  // Comment Form
  .comment-respond {
    margin-bottom: $spacing_md;
    background: #f5f5f5;
    padding: $spacing_sm $spacing_md;
    width: 100%;

    .section-title {  // Generic heading style
      font-size: 1.125rem;
      font-weight: 600;
      color: $vgc-darkgrey;
      margin-bottom: 1rem;
    }

    .comment-form {
      display: grid;
      gap: 1rem;
      width: 100%;

      label {
        font-weight: 500;
        font-size: 0.875rem;
        color: $vgc-darkgrey;
        margin-bottom: 0.25rem;
        display: block;
      }

      textarea,
      input[type="text"] {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #e0e0e0;
        font-size: 0.875rem;
        background: white;
        max-height: 130px;

        &:focus {
          border-color: $vgc-orange;
          outline: none;
        }
      }

      textarea {
        min-height: 80px;
        resize: vertical;
        padding: 0.5rem 0.75rem;
      }

      .submit-button {
        @extend .button-normal;
        justify-self: flex-start;
      }
      p.form-submit {
        margin-bottom: 0;
      }
    }
  }
}

/* Profile Section Styles */
.profile-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5; // Grey background matching the comment form
  padding: $spacing_sm $spacing_md;
  margin-bottom: $spacing_md;
}

.profile-info {
  display: flex;
  align-items: center;
  img {
    border-radius: 4px;
  }
}

.section-title {
  margin: 0;
  margin-left: 1rem; // Adds spacing between the avatar and the heading
  font-size: 1.125rem;
  font-weight: 600;
  color: $vgc-darkgrey;
  line-height: 1.3;
}

.section-title .friendly-name {
  display: inline-block;
  font-size: 0.875rem;
  vertical-align: middle;
  margin-left: 0.2rem;
  line-height: 1.3;
  font-weight: normal;
}

/* Friendly Name Form Styles */
.vgc-comments__friendly-name {
  width: 100% !important; // Force full width
  //margin-bottom: 1rem;
}

.vgc-comments__friendly-name__fields {
  // margin-bottom: 0.5rem;
  input {
    margin-bottom: 0.5rem;
  }
}

.vgc-comments__friendly-name__fields input,
.vgc-comments__friendly-name__fields button {
  display: block;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  background: white;
}

.vgc-comments__friendly-name button::after {
  content: none !important;
  display: none !important;
}

.vgc-comments__friendly-name__fields input:focus,
.vgc-comments__friendly-name__fields button:focus {
  border-color: $vgc-orange;
  outline: none;
}

.vgc-comments__friendly-name__fields button {
  background: $vgc-orange;
  color: $vgc-white;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: $vgc-yellow;
  }
}

.vgc-comments__friendly-name__name-error {
  margin-bottom: 0px;
  margin-top: 10px;
}

/* Logout Link Styled as a Button */
.logout-link {
  @extend .button-normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: $vgc-white;
  /* Remove float and extra margins */
  float: none;
  margin: 0;
  &:hover {
    color: #ffffff;
  }
}

.vgc-comments__patreon-button {
  margin-bottom: $spacing_lg;

  .ptrn-button {
    display: inline-block;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.02);
    }
  }
}

.comment-metadata {
  margin-top: -25px;
}

/* Report Icon Styling */
.zeno-report-icon .report-icon {
  width: 16px;
  height: 16px;
  filter: grayscale(100%) brightness(0) invert(40%); /* Approximates #999999 */
  transition: fill 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  margin-left: 4px;

  &:hover {
    filter: brightness(0) saturate(100%) invert(50%) sepia(77%) saturate(748%) hue-rotate(340deg) brightness(101%) contrast(91%); /* Approximates #f47820 */
    transform: scale(1.1); /* Slight scale effect on hover */
  }
}

/* Thanks Icon (Red Flag after reporting) */
.thanks-icon {
  width: 16px;
  height: 16px;
  filter: brightness(0) saturate(100%) invert(14%) sepia(92%) saturate(6109%) hue-rotate(357deg) brightness(95%) contrast(123%); /* Approximates #ff0000 */
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
}

/* Flagged Icon (Persistent icon after page reload) */
.flagged-icon {
  width: 16px;
  height: 16px;
  filter: brightness(0) saturate(100%) invert(14%) sepia(92%) saturate(6109%) hue-rotate(357deg) brightness(95%) contrast(123%); /* Approximates #ff0000 */
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
}



/*************************************************************************************************/
/* Media Breakpoints                                                                             */
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

@include media-breakpoint-up(md) { 
  // No additional width restrictions here
}

@include media-breakpoint-only(md) { }

@include media-breakpoint-up(lg) { }

@include media-breakpoint-only(lg) { }

@include media-breakpoint-up(xl) { }

@include media-breakpoint-only(xl) { }
