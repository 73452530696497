/*************************************************************************************************/
/** Header ***************************************************************************************/
/*************************************************************************************************/

.vgc-header {
  background: $white;
  padding: $spacing_sm;
  height: 70px;
  box-shadow: 1px 1px 5px rgba(0,0,0,.25);
  position: relative;

  // easy layout
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  // the SEO h1 on is_home()
  h1 {
    @include visuallyhidden();
  }

  // the logo
  &__logo {
    order: 2;

    svg {
      width: auto;
      height: 50px;
    }
  }

  // the nav items
  // these are moved to the off-canvas menu at this breakpoint.
  nav {
    display: none;

    @include brand-font();

    a {
      text-transform: uppercase;
      color: $vgc-black;

      &:visited {
        color: $vgc-black;        
      }
      &:hover,
      &:visited:hover {
        color: $vgc-orange;
      }
      &:active {
        color: $vgc-yellow;
      }
    }

    ul {
      list-style-type: none;
      list-style-image: none;
      padding: 0;
      margin: 0;
    }

    ul:nth-child(1) {
      @include largest-text();
      margin-bottom: $spacing_md;
    }

    ul:nth-child(2) { 
      @include small-text();

      li:not(:last-child) {
        margin-bottom: $spacing_sm;
      }

      a {
        color: $vgc-grey;

        &:visited {
          color: $vgc-grey;        
        }
        &:hover,
        &:visited:hover {
          color: $vgc-orange;
        }
        &:active {
          color: $vgc-yellow;
        }
      }
    }

  }

  // Off-canvas menu via 
  // https://medium.com/@heyoka/responsive-pure-css-off-canvas-hamburger-menu-aebc8d11d793

  .main-menu {
    position: absolute;
    display: none;
    left: -200px;
    top: 0;
    height: 100%;
    overflow-y: scroll;
    overflow-x: visible;
    //transition: left 0.2s ease;
    z-index: 999;
    background-color: $vgc-white;
  }
  .main-menu__wrapper {
    /* Hide shadow w/ -8px while 'closed' */
    box-shadow: -8px 0 8px rgba(0,0,0,.5);
    min-height: 100%;
    width: 200px;
    padding: $spacing_md;
  }

  /*
   :target for non-JavaScript
   [aria-expanded] will be used if/when JavaScript is added to improve interaction, though it's completely optional.
  */
  .main-menu:target,
  .main-menu[aria-expanded="true"] {
    display: block;
    left: 0;
    outline: none;
    box-shadow: 3px 0 12px rgba(0,0,0,.25);
  }

  /* 
   We could us `.main-menu:target:after`, but
   it wouldn't be clickable.
  */
  .main-menu:target + .backdrop,
  .main-menu[aria-expanded="true"] + .backdrop{
    position: absolute;
    display: block !important; // needs important because boostrap fuckery
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    background: $vgc-orange;
    @include background-gradient();
    opacity: 0.8;
    cursor: default;
  }

  @supports (position: fixed) {
    .main-menu,
    .main-menu:target + .backdrop,
    .main-menu[aria-expanded="true"] + .backdrop{
      position: fixed;
    }
  }

  .mobile-only {
    display: block;
    margin-top: $spacing_md;
  }

  // the trending list
  &__trending {
   display: none; 
   @include brand-font();

   ul {
    @include small-text();
    line-height: 1.5; // override because of small-text being too tight here.

      a {
        color: $vgc-grey;

        &:visited {
          color: $vgc-grey;        
        }
        &:hover,
        &:visited:hover {
          color: $vgc-orange;
        }
        &:active {
          color: $vgc-yellow;
        }
      }
   }

  }

  &__login {
    display: none;
    order: 1000 !important; // Ensure the login/logout element appears after the search form
    position: relative;
    width: 24px;  // Explicit icon dimensions
    height: 24px;
    padding: $spacing_sm; // Adjust for a comfortable clickable area
    text-decoration: none;
    background: none;

    .vgc-header__login-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 24px;
      transform: translate(-50%, -50%);
      transition: opacity 0.3s ease; // Smooth fade transition
      mask-size: contain;
      -webkit-mask-size: contain;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-position: center;
    }

    /* Logged Out State (Login) */
    &--loggedout {
      .vgc-header__login-icon--default {
        -webkit-mask-image: url('/wp-content/themes/alpha/dist/images/user-regular.svg');
                mask-image: url('/wp-content/themes/alpha/dist/images/user-regular.svg');
        background-color: $vgc-grey; // Default tint
        opacity: 1;
      }
      .vgc-header__login-icon--hover {
        -webkit-mask-image: url('/wp-content/themes/alpha/dist/images/person-to-door-regular.svg');
                mask-image: url('/wp-content/themes/alpha/dist/images/person-to-door-regular.svg');
        background-color: $vgc-orange; // Hover tint
        opacity: 0;
      }
      &:hover {
        .vgc-header__login-icon--default {
          opacity: 0;
        }
        .vgc-header__login-icon--hover {
          opacity: 1;
        }
      }
    }

    /* Logged In State (Logout) */
    &--loggedin {
      .vgc-header__login-icon--default,
      .vgc-header__login-icon--hover {
        -webkit-mask-image: url('/wp-content/themes/alpha/dist/images/right-from-bracket-regular.svg');
                mask-image: url('/wp-content/themes/alpha/dist/images/right-from-bracket-regular.svg');
      }
      .vgc-header__login-icon--default {
        background-color: $vgc-grey; // Default tint for logout icon
        opacity: 1;
      }
      .vgc-header__login-icon--hover {
        background-color: $vgc-orange; // Hover tint for logout icon
        opacity: 0;
      }
      &:hover {
        .vgc-header__login-icon--default {
          opacity: 0;
        }
        .vgc-header__login-icon--hover {
          opacity: 1;
        }
      }
    }
  }

  // the mobile/tablet nav toggles
  .menu-toggle,
  .nav-search {
    padding: $spacing_sm;
    background-color: $vgc-lightgrey;
    transition: background-color .1s ease-in-out;

    svg {
      width: 16px;
      height: 16px;
      display: block;
      fill: #000;
    }
  }
  .menu-toggle {
    order: 1;
    background-color: $vgc-lightgrey;

    &:hover {
      background-color: $vgc-orange;
      // Can't transition gradients, yet.
      // background: linear-gradient(45deg, $vgc-orange 0%, $vgc-yellow 100%);
      svg {
        fill: #fff;
      }
    }
  }
  .nav-search {
    order: 3;

    &:hover {
      background-color: darken($vgc-lightgrey, 25%);
    }
  }

  // the search form
  &__search {
    display: flex;

    &-close { 
      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: auto;
      padding: $spacing_sm / 2;
      img {
        width: 16px;
        height: auto;
        display: block;
      }
    }

    label {
      margin-right: $spacing_sm / 2;
      display: none;
    }
    input[type="search"] {
      border-color: $vgc-orange;
      outline: none !important;
      @include brand-font('400');
      background: #f5f5f5;
      color: $vgc-black;
      &::placeholder {
        color: $vgc-grey;
      }
      &:focus {
        &::placeholder {
          color: $vgc-black;
        }
      }
    }
    button {
      padding: 5px $spacing_sm 9px $spacing_sm;
      img, svg {
        fill: #fff;
        width: 16px;
        height: 16px;
      }
    }

    // actually, don't display it here.
    display: none;

    &.search-active {
      display: flex;
      justify-content: flex-end; 
      position: absolute;
      width: 100%;
      background: $vgc-white;
      padding: $spacing_sm 0; 
    }
  }



  .sr-only {
    @include visuallyhidden();
  }

}

// Branding bar that effectively hangs on to the header
.vgc-branding {
  
  @include background-gradient("45deg");  
  
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: ($spacing_sm - 2px) $spacing_sm;
    padding-top: ($spacing_sm - 1px); // ffs

    > span {
      @include brand-font();

      margin: 0;
      padding: 0;
      text-transform: uppercase;
      @include small-text();
      color: #fff;
      line-height: 1;
    }
  }

  ul {    
    @include list-plain();
    @include list-inline();    

    li:not(:last-of-type) {
      margin-right: .5em;
    }
    
    li {      
      color: #fff;
      line-height: 1;
    }
    li:first-of-type {
      @include brand-font;
      @include small-text;
      text-transform: uppercase;
      display: none;
      margin-right: 1.25rem;      
    }

    a {
      line-height: 0;
      svg {
        position: relative;
        top: -1px;
        fill: #fff;
        width: 15px;
        height: 15px;
      }
      &:hover, &:focus {
        svg {
          fill: $vgc-orange;
        }
      }
    }

  }

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {

  .vgc-branding {
    ul {    
      li:first-of-type {
        display: list-item;
      }
    }
  }

}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  .vgc-header {
    height: 100px;

    &__container {
      justify-content: flex-start;
      max-width: map-get($container-max-widths, lg);
      margin: 0 auto;
    }

    &__logo {
      order: 1;
      margin-left: $spacing_sm;
      margin-right: $spacing_lg;

      svg {
        height: 80px;
      }
    }


    nav {
      order: 2;
      display: block;

      ul {
        @include list-plain();
        @include list-inline();
        li:not(:last-of-type){
          margin-right: 1em;
        }
        line-height: 1;

        &:first-of-type {
          margin-bottom: $spacing_md / 2; 
        }
      
        &:nth-child(2){
          li:not(:last-child) {
            margin-bottom: 0;
          }
        }

      }


    }

    .menu-toggle,
    .nav-search {
      display: none;
    }

    .mobile-only {
      display: none;
    }

    &__search {
      order: 999;
      display: flex;
      margin-left: auto; // this is flexbox magic to align it right.
      margin-right: $spacing_md;

      #search {
        width: 300px - 36px; // same width as r/h column for OCD reasons
      }

      input[type="search"] {
        border-color: $vgc-grey; // because "too much orange in desktop header"
      }
      button {
        background: $vgc-grey; // same as above
        &:hover, &:focus {
          svg {
            fill: $vgc-orange;
          }
        }
      }

      &-close {
        display: none;
      }
    }

    &__login {
      display: inline-block;
    }

    .main-menu {
      position: relative;
      left: unset;
      top: unset;
      height: unset;
      display: block;
      overflow: hidden;
      box-shadow: none;
      width: unset;

      &__wrapper {
        box-shadow: none;
        min-height: 100%;
        width: unset;
        padding: 0;
      }
    }
    .backdrop {
      display: none !important;
    }

  }

  .vgc-branding {
    &__container {      
      max-width: map-get($container-max-widths, lg);
      padding-right: $spacing_md;
    }
  }

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {

  .vgc-header {

    &__container {
      max-width: map-get($container-max-widths, xl);
    }

    &__logo {
      margin-left: $spacing_lg;
    }

    &__trending {
      display: block; 
      order: 3;
      margin-left: $spacing_xl * 1.5;
      margin-bottom: -8px;
      display: flex;

      > span {
        font-weight: bold;
        text-transform: uppercase;
        color: $vgc-orange;
        display: block;
        margin-right: $spacing_sm;
      }

      ul {
        @include list-plain();
        padding-left: $spacing_sm;
        //padding-bottom: $spacing_md;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: -2px;
          height: 100%;
          width: 4px;
          background: linear-gradient(0deg, $vgc-orange 0%, $vgc-yellow 100%);;
        }
      }

    }

  }

  .vgc-branding {
    &__container {      
      max-width: map-get($container-max-widths, xl);
      padding-left: $spacing_lg;
    }
  }

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/