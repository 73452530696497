// Object meata stuff
.vgc-object__meta {
  border-top: 1px solid $vgc-lightgrey;
  padding-top: $spacing_sm;
  margin-top: $spacing_sm;

  h1,h2,h3,h4,h5,h6 {
    font-size: 1rem !important;
    a {
      color: $vgc-black !important;
      &:hover, &:focus {
        color: $vgc-orange !important;
      }
    }
  }

  &-image {
    width: 120px;
    line-height: 0;

    .bytes-image {
      padding-top: percentage(4 / 3);
    }
  }

}



/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/


@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/


@include media-breakpoint-up(md) {

  .vgc-object__meta {
    padding-top: $spacing_md;
    margin-top: $spacing_md;
    display: flex;

    padding-bottom: $spacing_md;

    &-image {
      margin-right: $spacing_md;
    }

    &-credits {
      dl {
        margin: 0 $spacing_lg 0 0;
        line-height: 1.4;
        > div:not(:last-child) {
          margin-bottom: $spacing_sm / 2;
        }
      }
    }

    &-dates {
      ul {
        line-height: 1.4;
      }
    }

  }

}

@include media-breakpoint-only(md) { }


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/


@include media-breakpoint-up(lg) {

  .vgc-object__meta {
    padding-top: $spacing_lg;
    margin-top: $spacing_lg;
  }

}

@include media-breakpoint-only(lg) { }


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/


@include media-breakpoint-up(xl) {
}

@include media-breakpoint-only(xl) { }