/*************************************************************************************************/
/** Posts ****************************************************************************************/
/*************************************************************************************************/

.post {

  &__header {
//    @if ($debug == true) { outline: 2px dashed rgba(#00f, 0.33); }

    &-sponsored {
      margin-bottom: $spacing_sm;
    }

    &-main {
      @if ($debug == true) { background: rgba(#0f0, 0.05); }

      margin-bottom: $spacing_sm;

      h1 {
        @include heading-scale("sm", "2");
      }

      .authored {
        margin-top: 5px;
        font-style: italic;
        color: $vgc-purple;
        @include heading-scale("sm", "4");
        /*&:before {
          display: inline;
          content: '- ';
          color: $vgc-grey;
        }*/
        figure {
          display: none;
          @include corner-bit("3px");
          background: linear-gradient(0deg, $vgc-grey, $vgc-lightgrey);
        }
      }
      
      p {
        @include brand-font("400");
        @include heading-scale("sm", "4");
        line-height: 1.3;
        text-transform: uppercase;
        color: $vgc-grey;
        margin: 0;
      }

    }

    // Review stars template part need overriding
    &-review {
      margin-bottom: $spacing_sm;

      .review-details {
        border-top: 1px solid $vgc-lightgrey;
        padding-top: $spacing_sm;
        margin: 0;

        &__score {
          @include corner-bit("2px");
          margin: $spacing_sm 30% 0 30%;
          padding: ($spacing_sm * .75) ($spacing_sm / 2);

          svg {
            margin: 0 $spacing_sm / 3;
            width: 16px;
            height: 16px;
          }
        }

        &__credits {
          margin-top: $spacing_sm;
          @include small-text();

          dl {
            margin: 0;
          }
        }

        &__summary,
        &__positives-negatives,
        &__version-tested {
          display: none;
        }

      }
    }

    &-extras {
      @if ($debug == true) { background: rgba(#f00, 0.05); }

      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: $spacing_sm;
      padding-bottom: $spacing_sm;
      border-bottom: 1px solid $vgc-lightgrey;
    }

    &-meta {
      @include meta();
      margin: ($spacing_sm / 2) 0 0 0;
    }

    &-quotemark {
      color: $vgc-purple;
      svg {
        fill: $vgc-purple;
        width: 48px;
        height: 48px;        
      }
    }

    &-tags {
      a {
        background: none; // fixes sub-pixel black line
        @include background-gradient();
        margin-bottom: 0;
      }
    }

    // the default header
    &--default {

    }

    // the featured header
    &--featured {
    }


  }


  &__featured-image {
    margin-bottom: $spacing_sm;

    position: relative;
    overflow: hidden;
    line-height: 0;

    .bytes-image {
      padding-top: percentage(9 / 16); // was 21:9
      @include corner-bit("10px");
    }
    .no-image {
      @include heading-underlined("small");
    }

  }

  &__featured-video {
    margin-bottom: $spacing_sm;
  }


  &__content {
//    @if ($debug == true) { outline: 2px dashed rgba(#0f0, 0.33); }

    &-main {
      @if ($debug == true) { background: rgba(#0f0, 0.05); }

      line-height: 1.75; // my eyes!

      figure img {
        background: $bytes-grey;
        min-height: 2em; // just for a loading indicator if nothing else...
        opacity: 1 !important;
        line-height: 100%;
        font-size: 12px;
        font-weight: 400;
        color: $vgc-grey;
        outline: none !important;
      }      

      figure.alignoutside {
        display: none;
        img {
          background: none !important;
        }
      }

      .twitter-tweet-rendered {
        margin-right: auto;
        margin-left: auto;
        width: 520px !important; // after this embedded tweets go weird.
      }

      .review-details {
        position: relative;
        padding-top: $spacing_sm;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          height: 5px;
          width: 100%;
          @include background-gradient("90deg", $vgc-purple, $vgc-plum);
        }

        &__credits {
          display: none;
        }
      }

    }

    &-meta {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: $spacing_sm;
      line-height: 1.2;

      padding-top: 11px; // OCD!

      .author-byline { 
        span, h4 {
          display: inline;
          margin: 0 .2em 0 0;
          padding: 0;
          font-size: 100% !important;
        }
        h4 {
          a {
            font-weight: normal;
            color: $vgc-orange;
          }
        }
        h5 {
          @include visuallyhidden();
        }
      }

      time {
        @include meta();
        margin: 0;
      }

    }


    &-header {
//      @if ($debug == true) { outline: 2px dotted rgba(#00f, 0.33); }

      @include small-text();
      color: $vgc-grey;
      border-bottom: 1px solid $vgc-lightgrey;
      padding-top: $spacing_sm / 2;
      padding-bottom: $spacing_md;
      margin-bottom: $spacing_md;

      a {
        // Override link styles, else ugly mess results
        border: none !important;
      }

      p:last-of-type {
        margin: 0;
      }

    }

    &-body {
//      @if ($debug == true) { outline: 2px dotted rgba(#0f0, 0.33); }

      > p:first-of-type {
        @include font-size(20);
        @include brand-font("400");
        margin-bottom: 1.5rem;
        padding-top: 2px; // OCD!
      }

      > figure {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }

      h3, 
      h4 {
        text-transform: uppercase;
      }
      h3 {
        position: relative;
        @include heading-underlined("small", "orange");
      }
      h4 {
        color: $vgc-orange;
      }

      .video-container,
      .entry-content-asset--video {
        margin-bottom: 1em;
      }

      // The little sign-off thingy
      .signoff {
        margin-bottom: 1rem;
        svg {
          width: 24px;
          height: 24px;
        }
      }


    }

    &-footer {
//      @if ($debug == true) { outline: 2px dotted rgba(#f00, 0.33); }

      @include small-text();
      color: $vgc-grey;
      border-top: 1px solid $vgc-lightgrey;
      padding-top: $spacing_md;
      margin-top: $spacing_md;

//      margin-bottom: $spacing_md;

      a {
        // Override link styles, else ugly mess results
        border: none !important;
      }

      p:last-of-type {
        margin: 0;
      }
    }


    &-extras {
      @if ($debug == true) { background: rgba(#f00, 0.05); }

      display: none; // ???
    }

  }

  &__footer {
//    @if ($debug == true) { border-top: 2px dashed rgba(#f0f, 0.25); }

    &-main {
      @if ($debug == true) { background: rgba(#0f0, 0.05); }

      border-top: 1px solid $vgc-lightgrey;
      padding-top: $spacing_md;
      margin-top: $spacing_md;

      margin-bottom: $spacing_md;

    }

    &-extras {
      @if ($debug == true) { background: rgba(#f00, 0.05); }

      border-top: 1px solid $vgc-lightgrey;
      padding-top: $spacing_md;
      margin-top: $spacing_md;

      margin-bottom: $spacing_md;

    }
  }

  &__footer--expanded {
    display: none;
  }

  .ident-inbrief {
    margin-bottom: 1em;
  }

}

// Pad up the L/H extras column on In Brief, to account for no comment/social starter
.post.inbrief {
  .post__content-extras {
    padding-top: 24px;
  }
}

.affiliate-notice {
  @include small-text();
  color: $vgc-grey;
  clear: both;
}

// Different post types
.post--featured {

  // Can't use the .post {} as a prefix here because of class ordering inheritance in WP
  .post__header {

    .post__header-main {
      text-align: center;

      h1 {
        text-transform: uppercase;
        @include heading-scale("sm","1");
        line-height: 1;
      }
      p {
        //color: $vgc-grey;
      }

    }

    .post__header-extras { 
      display: block;
      text-align: center;
      margin-bottom: $spacing_sm;
    }

    .post__header-tags {
      text-align: center;
      margin-bottom: $spacing_sm;

      a {
        @include background-gradient("90deg", $vgc-purple 0%, $vgc-plum 100%);
      }
    }


    .post__header-meta {
      display: block;
    }

  }

  .post__content {
    .post__content-body {
      h3 {
        @include heading-underlined("small", "purple");
      }
      h4 {
        color: $vgc-purple;
      }
    }
  }

  .vgc-header__ident {
    @include background-gradient("0deg", rgba($vgc-purple, 0.9), rgba($vgc-plum, 0.9));
  }
  
}

.post--blog {

  // Can't use the .post {} as a prefix here because of class ordering inheritance in WP
  .post__header {

    .post__header-main {
      .authored {
        color: $vgc-blue;        
      }
    }

    .post__header-quotemark {
      svg {
        fill: $vgc-blue;
      }
    }

    .post__header-tags {
      a {
        @include background-gradient("90deg", $vgc-blue 0%, $vgc-cyan 100%);
      }
    }

  }

  .post__content {
    .post__content-body {
      h3 {
        @include heading-underlined("small", "blue");
      }
      h4 {
        color: $vgc-blue;
      }
    }
  }

  .vgc-header__ident {
    @include background-gradient("0deg", rgba($vgc-blue, 0.9), rgba($vgc-cyan, 0.9));
  }
  
}

.post--opinion {

  // Can't use the .post {} as a prefix here because of class ordering inheritance in WP
  .post__header {

    .post__header-tags {
      a {
        @include background-gradient("90deg", $vgc-purple 0%, $vgc-plum 100%);
      }
    }

  }

  .post__content {
    .post__content-body {
      h3 {
        @include heading-underlined("small", "purple");
      }
      h4 {
        color: $vgc-purple;
      }
    }
  }

  .vgc-header__ident {
    @include background-gradient("0deg", rgba($vgc-purple, 0.9), rgba($vgc-plum, 0.9));
  }
  
}

.post--guide {

  // Can't use the .post {} as a prefix here because of class ordering inheritance in WP
  .post__header {

    .post__header-tags {
      a {
        @include background-gradient("90deg", $vgc-rust 0%, $vgc-red 100%);
      }
    }

  }

  .post__content {
    .post__content-body {
      h3 {
        @include heading-underlined("small", "red");
      }
      h4 {
        color: $vgc-red;
      }
    }
  }

  .vgc-header__ident {
    @include background-gradient("0deg", rgba($vgc-rust, 0.9), rgba($vgc-red, 0.9));
  }
  
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/


@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/


@include media-breakpoint-up(md) {

  .post {

    &__header {

      &-main {
        margin-bottom: $spacing_md;

        h1 {
          @include heading-scale("md", "2");
          margin-top: 0-$spacing_sm; //because of stupid cap height
        }

        p {
          @include heading-scale("md", "4");
          line-height: 1.3;
        }

        .authored {
          @include heading-scale("md", "4");
        }

      }


    // Review stars template part need overriding
    &-review {

      .review-details {
        margin: 0;
        padding-top: 0;
        display: flex;
        align-items: flex-start;

        
        &__score {
          order: 1;
          margin: $spacing_md $spacing_md $spacing_md 0;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        &__credits {
          order: 2;
          border-left: 1px solid $vgc-lightgrey;
          margin: 0;
          padding: $spacing_md 0 $spacing_md $spacing_md;
          text-align: left;
        }

      }
    }  

      &-extras {
        margin-bottom: $spacing_md;
        align-items: flex-start;
      }

    } 

    &__content {
      &-main {


        figure.alignleft,
        figure.alignright {
          width: 50%;
        }
        figure.alignleft {
          float: left;
          margin-right: $spacing_md;
          figcaption {
            text-align: left;
          }
        }
        figure.alignright {
          float: right;
          margin-left: $spacing_md;
          figcaption {
            text-align: right;
          }
        }

        .review-details {
          padding-top: $spacing_md;
        }

      }

      &-header {
      }

      &-body {
        
        font-size: 110%;// was looking a bit teeny-tiny

        > p:first-of-type {
          @include font-size(22);
        }
        h3 {
          @include heading-underlined("normal", "orange");
        }

      }
      
      &-footer {
      }

    }

    &__featured-image {

      .bytes-image {
        @include corner-bit("20px");
      }
      .no-image {
        @include heading-underlined("normal");
      }

    }

    &__featured-video {
    }

  }


  // Different post types
  .post--featured {

    .post__header {

      .post__header-main {

        h1 {
          @include heading-scale("md","1");
          line-height: 1;
        }

      }

      .post__header-extras {
      }

      .post__header-tags {
      }

      .post__header-meta {
      }

    }

    .post__content {
      .post__content-body {
        h3 {
          @include heading-underlined("normal", "purple");
        }
      }
    }    
    
  }

  .post--guide {

    .post__content {
      .post__content-body {
        h3 {
          @include heading-underlined("normal", "red");
        }
      }
    }    
    
  }

  .post--blog {

    .post__content {
      .post__content-body {
        h3 {
          @include heading-underlined("normal", "blue");
        }
      }
    }    
    
  }

}

@include media-breakpoint-only(md) { }


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/


@include media-breakpoint-up(lg) {

  .post {

    &__header {

      &-main {
        margin-bottom: $spacing_md;
        h1 {
          @include heading-scale("lg", "2");
        }
        p {
          @include heading-scale("lg", "4");
          line-height: 1.3;
        }

        .authored {
          @include heading-scale("lg", "3");
        }        

      }

      &-review {
        margin-bottom: $spacing_md;
      }

      &-extras {
        margin-bottom: $spacing_md + 5px; // weird little extra because of subheading line-height;
      }

    }

    &__content {
      &-main {

        .review-details {
          padding-top: $spacing_lg;
        }

      }

      &-header {
      }

      &-body {

        > p:first-of-type {
          @include font-size(24);
        }        

        h3 {
          @include heading-underlined("large", "orange");
        }

      }
      
      &-footer {
      }      
    }

    &__featured-image {
      margin-bottom: $spacing_md;

      .no-image {
        @include heading-underlined("large");
      }

    }

    &__featured-video {
      margin-bottom: $spacing_md;
    }

  }


  // Different post types
  .post--featured {

    .post__header {

      .post__header-main {

        h1 {
          @include heading-scale("lg","2");
          line-height: 1;
        }

      }

      .post__header-extras {
        margin-bottom: $spacing_lg;
      }

      .post__header-tags {
        margin-bottom: $spacing_md;
      }

    }

    .post__content {
      .post__content-body {
        h3 {
          @include heading-underlined("large", "purple");
        }
      }
    }
    
  }

  .post--guide {

    .post__content {
      .post__content-body {
        h3 {
          @include heading-underlined("large", "red");
        }
      }
    }
    
  }

  .post--blog {

    .post__content {
      .post__content-body {
        h3 {
          @include heading-underlined("large", "blue");
        }
      }
    }
    
  }

}

@include media-breakpoint-only(lg) { }


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/


@include media-breakpoint-up(xl) {

  .post {

    &__header {
      display: flex;

      &-main {
        order: 2;
        width: 100%;
        margin-left: $spacing_lg;

        .authored {
          &:before {
            display: none;
          }
          figure {
            display: block;
            float: left;
            //outline: 2px solid #f0f;            
            width: 42px;
            height: 42px;
            margin-right: 10px;
          }
        }
      }

      &-extras {
        display: flex;
        flex-direction: column;
        border: 0;
        padding-bottom: 0;

        order: 1;
        flex: 0 0 230px;
        max-width: 230px; // stop fat things breaking out
      }

      &-quotemark {
        svg {
          width: 180px;
          height: 180px;
          position: relative;
          top: 13px;
        }
      }

    }

    &__featured-image {    

      .bytes-image {
//        padding-top: percentage(9 / 21); // Drop to 21:9 for ad viewability ?!
      }

    }

    &__content {
      display: flex;

      &-main {
        order: 2;
        width: 100%;

        figure.aligncenter {
          margin-top: 2em;
          margin-bottom: 2em;
        }

        figure.alignoutside {
          display: block;
          float: left;
          width: 230px;
          margin-left: -260px;
        }

      }

      &-extras {
        display: block;
        order: 1;
        flex: 0 0 230px;
        max-width: 230px; // stop fat things breaking out
        margin-right: 30px;

        h6, span.h6 {
          @include brand-font();
          display: block;

          @include heading-scale("lg","6");
          @include heading-underlined("small");
          margin-bottom: $spacing_sm;
        }

        .vgc-post {
          margin-bottom: $spacing_sm;

          &__info {
            .headline, h1, h2, h3, h4, h5, h6 {
              @include heading-scale("lg","5");
            }
          }
        }

        .vgc-object__meta {
          margin: 0;
          padding: 0;
          border-top: none;

          &-image {
            width: 80px;
            flex-basis: 80px;
          }

          h3 {
            //display: none;
          }

          dl {
            @include small-text();
            > div {
              display: block;
              margin-bottom: $spacing_sm / 2;
            }
          }

        }

      }

    }

    &__footer {
      display: flex;

      &-main {
        order: 2;
        width: 100%;
        margin-bottom: 0;        
      }

      &-extras {
        display: block;
        order: 1;
        flex: 0 0 230px;
        max-width: 230px; // stop fat things breaking out
        margin-right: 30px;
        margin-bottom: 0;

        h6, span.h6 {
          @include brand-font();
          display: block;

          @include heading-scale("lg","6");
          @include heading-underlined("small");
          margin-bottom: $spacing_sm;
        }

        .vgc-post {
          margin-bottom: $spacing_sm;

          &__info {
            .headline, h1, h2, h3, h4, h5, h6 {
              @include heading-scale("lg","5");
            }
          }
        }

      }

    }    

  }

  // Different post types
  .post--featured {

    .post__header {
      display: block;

      .post__header-main {
        margin-left: 0;

        h1 {
          @include heading-scale("lg","1");
          line-height: 1;
        }

      }

      .post__header-extras {
        max-width: unset;
      }

    }

  }

  .post--featured,
  .post--opinion {    

    .post__content {

      .post__content-main {

        figure.aligncenter,
        blockquote,
        .vgc-video {
          margin-left: -260px;
        }

      }

    }

  }

  .post--guide,
  .post--blog {    

    .post__content {

      .post__content-main {

        blockquote {
          margin-left: -260px;
        }

      }

    }

  }

  .affiliate-notice {
    padding-top: 11px; // in line with the socials, please
    p {
      margin: 0;
    }
  }

}

@include media-breakpoint-only(xl) { }


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/