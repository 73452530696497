/*************************************************************************************************/
/** Footer ***************************************************************************************/
/*************************************************************************************************/

.vgc-footer {
  background: $vgc-darkgrey;
  color: $vgc-white;
  position: relative;

  padding-bottom: 120px; // account for sticky ad

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    top: 0;
    left: 0;
    background-image: linear-gradient(90deg, $vgc-orange 0%, $vgc-yellow 100%);
  }

  &__logo {
    display: inline-block;
    margin-bottom: $spacing_sm;

    svg {
      width: 50px;
      height: auto;
    }
  }

  &__container {        
    display: grid;
    grid-template-columns: 100fr;
    grid-template-rows: auto;
    grid-gap: $spacing_sm;
    padding: $spacing_sm;
    padding-top: $spacing_md + 4px; // to account for the ::before
  }

  section {    

    > span {
      display: block;
      margin-bottom: .5em;
      @include brand-font();
      @include heading-underlined("small");
      @include heading-scale("sm", "5"); 
      text-transform: uppercase;
      color: $vgc-white;
    }

    p:last-of-type {
      margin: 0;
    }

    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }  

  ul {
    @include list-plain();
  }  

  &__explore {
    display: none;
  }

  &__logo {
    float: left;
    margin-right: $spacing_md;
  }

  &__legal {    
    @include small-text();
    a {
      color: unset;
      &:focus, &:hover {
        text-decoration: underline;
      }
    }
    p:last-of-type {
      clear: both;
      margin-bottom: 1rem !important;
    }
  }

  &__credits {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    .credit-44bytes {
      display: inline-block;
      background: url(add_asset_path('images/44bytes.svg'));
      background-size: 94px 40px;
      background-repeat: no-repeat;
      width: 94px;
      height: 40px;    
      text-indent: -3333px;    
      vertical-align: bottom;
    }
  }  

}

@if $debug == true {
  .vgc-footer {
    outline: 1px dotted rgba(#f0f, 0.25);
    &__container {
      outline: 1px dotted rgba(#f00, 0.5);
    }
    section {
      outline: 1px dotted rgba(#f0f, 0.5);
    }
  }
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {

  .vgc-footer {

    &__legal {      
      grid-area: legal;          
      p:last-of-type {
        clear: unset;
      }
    }
    &__credits {
      grid-area: credits;      
    }
    &__explore {
      display: block;
      grid-area: explore;
    }
    &__helpful {
      display: block;
      grid-area: helpful;
    }
    &__gn-sites {
      grid-area: gn-sites;
    }

    &__container {
      grid-template-columns: 34fr 33fr 33fr;
      grid-template-areas:
        "legal legal legal"
        "helpful explore gn-sites"        
        "credits credits credits";
    }

  }

}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .vgc-footer {

    &__gn-sites {    
      display: block;      
    }

    &__container {
      padding: $spacing_md;
      padding-top: $spacing_md + 4px; // to account for the ::before
      grid-template-columns: 33fr 33fr 34fr;    
      grid-template-areas:
        "legal legal legal"
        "helpful explore gn-sites"
        "credits credits credits";
      grid-gap: $spacing_md;
    }

    section {

      > span {
        @include heading-scale("md", "5");
      }
    }

  }  

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  .vgc-footer {    

    &__container {
      padding: $spacing_lg;
      padding-top: $spacing_lg + 4px; // to account for the ::before
      max-width: map-get($container-max-widths, lg);
      margin: 0 auto;

      grid-template-columns: 34fr 22fr 22fr 22fr;
      grid-template-rows: auto;
      grid-template-areas:
        "legal helpful explore gn-sites"
        "credits helpful explore gn-sites";
      grid-gap: $spacing_lg;
    }

    section {
      > span {
        @include heading-scale("lg", "5");
      }
    }

    &__logo {
      float: unset;
    }

    &__legal {
      font-size: 100%;
    }

  }  

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {

  .vgc-footer {

    &__container {
      max-width: map-get($container-max-widths, xl);
    }

  }  

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/