/*************************************************************************************************/
/** Settings *************************************************************************************/
/*************************************************************************************************/

// Debugging switch - turn this on for additional outlines/highlights
$debug: false;

// Spacings
$spacing_sm: 10px;
$spacing_md: 15px;
$spacing_lg: 30px;
$spacing_xl: 60px;

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/