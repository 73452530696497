/*************************************************************************************************/
/** Bootstrap Settings ***************************************************************************/
/*************************************************************************************************/

// Any defaults from ../../bower_components/bootstrap/scss/_variables.scss that
// need to be overridden should be done here. Make sure that you understand how
// Bootstrap is using these (including the !default values it starts with) to
// avoid the pitfall of overriding a bunch of things manually later, only to 
// realise you could have added a tweak here to sort it properly.

$primary:            $vgc-orange;

$font-family-base:   "Lato",sans-serif;

$body-color:         #2d2d2d;

$line-height-base:   1.6;
$line-height-lg:     1.6;
$line-height-sm:     1.6;

$grid-columns:       12;
$grid-gutter-width:  30px;

$grid-breakpoints: (
  xs: 0,
  sm: 500px,
  md: 768px,
  lg: 992px,
  xl: 1260px // was 1280
);

$container-max-widths: (  
//  md: 728px,
  lg: 970px,
  xl: 1260px
);

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/