// CSS Grid powered gallery styles
// Based on https://codepen.io/balazs_sziklai/pen/mOwoLg

.gallery  {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-auto-rows: minmax(90px, auto);
  grid-gap: 10px;
  grid-auto-flow: dense;
  margin-top: 2em;
  margin-bottom: 2em;

  &-item {
    line-height: 0;
    background-color: #333;
    
    .bytes-image {
      padding-top: percentage(9/16);
      //height: 100%;
    }

    figure {
      margin: 0;
    }
  }

}

// Ghetto non-grid versions
@supports not (display: grid) {
  .gallery  {
    display: flex;
    justify-content: space-between;

    &-item {
      width: 49%;
      margin-bottom: $spacing_sm;
    }
  }
}


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: minmax(110px, auto);

    &-item {      
      .bytes-image {
        height: 100%;
      }
    }
  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {


  .gallery {

    &-item:nth-child(3n) {
      grid-column-end: span 2;
      grid-row-end: span 2;
    }

  }

  // Ghetto non-grid versions
  @supports not (display: grid) {
    .gallery  {
      &-item {
        width: 32.333%;
      }
    }
  }  

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {


  .gallery {
    margin-left: -260px;

    &-item:nth-child(3n) {
      grid-column-end: span 2;
      grid-row-end: span 2;
    }
    &-item:nth-child(6n) {
      grid-column-end: span 4;
      grid-row-end: span 4;
    }

  }

}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/