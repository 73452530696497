/*************************************************************************************************/
/** Video Player *********************************************************************************/
/*************************************************************************************************/

.vgc-video {  

  margin-top: 2rem;
  margin-bottom: 2rem;

  .vgc-video__header {   
    padding-bottom: 10px; 
    h1, h2, h3, h4, h5, h6 {
      margin: 0 !important;
      padding: 0 !important;
      border: none;
      font-size: 16px;
      color: $vgc-grey;
      &::after {
        display: none;
      }
      svg {
        width: 19px;
        height: 19px;
        margin-right: 8px;
        fill: $vgc-orange;
      }
    }
  }

  .vgc-video__player {
    background: $bytes-grey;
    &::before {
      content: " ";
      display: block;
      width: 100%;
      height: 9px;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFCAYAAAB8ZH1oAAAAAXNSR0IArs4c6QAAACxJREFUGFdjVFJS+n/37l0GdKCsrIwixAhSCBIhpBiukJBiFIX4FGMoxKUYAH/bFZao4qSjAAAAAElFTkSuQmCC') repeat;
      border: 2px solid #222;
    }
    &::after {
      content: " ";
      display: block;
      width: 100%;
      height: 9px;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFCAYAAAB8ZH1oAAAAAXNSR0IArs4c6QAAAC9JREFUGFdjVFJS+s+ABu7evYsuxMCIrhCbImVlZVSFuBSBjIebiE8RXCEhRSCFADyXFZacs9gfAAAAAElFTkSuQmCC') repeat;
      border: 2px solid #222;
    }
    .bbw-container {
      aspect-ratio: 16/9;
    }
  }

  .vgc-video__footer {
    @include clearfix();

    padding-top: 3px;

    a.ytlink {
      float: left; 
    }
    a.ytchannel {
      float: right;
    }

    a {
      font-size: 0.8em;
    }

    svg {
      height: 12px;
      fill: $vgc-black;
      position: relative;
      top: -2px;
    }
  }

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {
}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/