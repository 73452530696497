// Review details
.review-details {
  margin-top: $spacing_sm;
  margin-bottom: $spacing_sm;

  > span {
    @include visuallyhidden(); 
  }

  &__score {
    @include scoreblock(); 
  }

  &__credits {
    dl {
      dt {
        @include brand-font();
        white-space: nowrap;
      }
      dd {
        a,
        span {
          white-space: nowrap;   
        }
      }
    }
  }

  &__summary {
    @include brand-font();
    @include large-text();
  }

  &__version-tested {
    // FIXME: luc - this is a new thing
    dt {
      text-transform: uppercase;
    }
  }

  &__positives-negatives {
    margin-bottom: $spacing_sm;

    ul {
      @include list-plain();

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .positive,
    .negative {

      svg {
        width: 16px;
        height: 16px;
        margin-right: $spacing_sm;
      }

    }

    .positive {
      svg {
        path {
          fill: $vgc-purple;
        }
      }
    }
    .negative {
      svg {
        transform: scaleY(-1);
        path {
          fill: $vgc-grey;
        }
      }
    }

  }
}


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .review-details {
    margin-top: $spacing_md;
    margin-bottom: $spacing_md;

    &__positives-negatives {
      margin-bottom: $spacing_md;
    }

    &__score {
      padding: $spacing_md;

      svg {
        width: 42px;
        height: 42px;
      }

    }

  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  .review-details {
    margin-top: $spacing_lg;
    margin-bottom: $spacing_lg;

    &__positives-negatives {
      margin-bottom: $spacing_lg;
    }

    &__score {
      padding: $spacing_lg;
      
      svg {
        width: 64px;
        height: 64px;
      }

    }

  }

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/