/*************************************************************************************************/
/** Page headers *********************************************************************************/
/*************************************************************************************************/

.vgc-pageheader {
  margin-bottom: $spacing_sm;

  > h1 {
    @include heading-scale("sm","1");
    @include heading-underlined("small");
    text-transform: uppercase;
    color: $vgc-black;
  }

  .bytes-image {
    padding-top: percentage(9 / 16);
    @include corner-bit("10px");
  }

  .bytes-image__overlay--bottom {
    padding: ($spacing_sm * 2) $spacing_sm $spacing_sm $spacing_sm;
    color: $vgc-white;
    
    h1 {
      color: $vgc-white;
      text-transform: uppercase;
      @include heading-scale("sm","0");
      line-height: 1;
      text-shadow: 0 0 5px rgba($vgc-purple, .15);
      margin: 0;
      padding: 0;
      &::after {
        display: none;
      }
      span.garnish {        
        opacity: 0.9;
      }
    }
    h2.garnish {
      opacity: 0.9;
      color: $vgc-white;
      text-transform: uppercase;
      @include heading-scale("sm","1");
      margin-top: 10px;
      margin-bottom: 0;
      line-height: 1;
      text-shadow: 0 0 5px rgba($vgc-purple, .15);
      display: none;
      padding-bottom: 0 !important;
      &:after {
        display: none !important;
      }
    }

    background-image: linear-gradient(0deg, $vgc-orange 0%, rgba($vgc-yellow, 0) 100%);
  }

  .bytes-image__overlay--topleft {
    top: $spacing_sm;
    left: $spacing_sm;
    .vgc-tag {
      background: none; // fixes sub-pixel black line
      @include background-gradient("45deg", $vgc-orange, $vgc-yellow);
      
      // Can't cheat with white here :-(
      &:after {
        display: none;
      }      
      clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 0 100%);

    }
  }


  // Variants for other types of header
  &--platforms,
  &--events {
    .bytes-image {
      padding-top: percentage(1 / 2);

      &__overlay--bottom {
        background: none; // because the orange gradient will clash with a brand colour.        
      }
    }
  }

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {

  .vgc-pageheader {
    .bytes-image__overlay--bottom {
      h1 {
        span.garnish {        
          display: inline;
        }
      }
      h2.garnish {
        display: block;
      }
    }
  }

}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .vgc-pageheader {
    margin-bottom: $spacing_md;

    > h1 {
      @include heading-scale("md","1");
      @include heading-underlined("normal");
    }

    .bytes-image {
      @include corner-bit("20px");
    }

    .bytes-image__overlay--bottom {
      padding: ($spacing_md * 2) $spacing_md $spacing_md $spacing_md;
      
      h1 {
        @include heading-scale("md","0");
        line-height: 1;
      }

    }

    .bytes-image__overlay--topleft {
      top: $spacing_md;
      left: $spacing_md;
    }


    // Variants for other types of header
    &--platforms,
    &--events {
      .bytes-image {
        padding-top: percentage(1 / 3);
      }
    }

  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  .vgc-pageheader {

    > h1 {
      @include heading-scale("lg","1");
      @include heading-underlined("large");
    }

    .bytes-image__overlay--bottom {
      h1 {
        span.garnish {        
          display: none;
        }
      }
      h2.garnish {
        display: none;
      }
    }

    .bytes-image__overlay--bottom {
      padding: ($spacing_lg * 2) $spacing_lg $spacing_lg $spacing_lg;
      
      h1 {
        @include heading-scale("lg","1");
        line-height: 1;
      }

    }

    .bytes-image__overlay--topleft {
      top: $spacing_lg;
      left: $spacing_lg;
    }

    // Variants for other types of header
    &--platforms,
    &--events {
      .bytes-image {
        padding-top: percentage(1 / 4);
      }
    }

    &--games {
      .bytes-image {
        &__overlay {
          h1 {
            @include heading-scale("lg","0");
          }
        }
      }
    }
    
  }  

}

@include media-breakpoint-only(lg) {}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {

  .vgc-pageheader {

    .bytes-image__overlay--bottom {
      
      h1 {
        @include heading-scale("lg","1");
        line-height: 1;
        span.garnish {        
          display: inline;
        }
      }
      h2.garnish {
        display: block;
      }

    }

    // Variants for other types of header
    &--platforms {}

    &--games {
      .bytes-image {
        &__overlay {
          h1 {
            @include heading-scale("lg","0");
          }
        }
      }
    }
    
  } 
  
}