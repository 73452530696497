/*************************************************************************************************/
/** OneSignal ************************************************************************************/
/*************************************************************************************************/

#onesignal-slidedown-container.onesignal-slidedown-container {
  #onesignal-slidedown-dialog.onesignal-slidedown-dialog {
    background: #212529 !important;
    border-radius: 0 !important;

    box-shadow: rgba(0,0,0,0.66) 1px 1px 8px 2px !important;

    &:before {
      content: " ";
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 5px;
      bottom: 0;
      background: $vgc-yellow;
      background-image: linear-gradient(0deg,#f47820,#feb823);
    }

    .slidedown-body-message{
      color: #fff !important;
    }

    #onesignal-slidedown-allow-button {
      background: #28a745 !important;
      padding: 8px 16px !important;
      color: #fff !important;
      font-weight: bold !important;
    }
    #onesignal-slidedown-cancel-button {      
      padding: 8px 16px !important; 
      background: none !important;
      border: none !important;
      text-decoration: underline;
      color: #fff !important;
    }

  }
}

#onesignal-slidedown-container.slide-up {
  bottom: unset !important;
  top: 0 !important;
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {

}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/