/*************************************************************************************************/
/** Common ***************************************************************************************/
/*************************************************************************************************/

// Always show a disabled scrollbar, to stop jittery page transitions  
html {
  overflow-y: scroll !important;
  overflow-x: hidden;
}

// Extra padding for mobile nav when the WP admin bar is present
body.admin-bar {
  #wpadminbar {
    background: $vgc-darkgrey !important;    
  }
  .main-menu {
//    padding-top: 32px !important;
  }
}
@media screen and (max-width: 782px) {
  body.admin-bar {
    .main-menu {
//      padding-top: 46px !important;
    }
  }
}

// A foolproof way to detect active breakpoint
#mq {
  width: 320px;
  height: 10px;
  margin: 0 auto;
  background-color: #f00;
  position: absolute;
  top: -9999px;
  left: -9999px;  
}

// Breakpoint debugger
#breakpoint {
  position: fixed; 
  top: 0; 
  left: 0; 
  padding: 0px 8px; 
  font-size: 13px;
  font-weight: 400;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  line-height: 32px;
  background: rgba(#f0f, 0.66); 
  backdrop-filter: blur(4px);
  color: #fff;
  z-index: 999999999;
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {

  #mq { width: 500px; }

}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  #mq { width: 768px; }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  #mq { width: 992px; }

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {

  #mq { width: 1280px; }  

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/