// Re-usable / individual sponsored stuff
.sponsored-strip {
  display: flex;
  justify-content: center;
  align-items: center;

  .sponsored-text {
    width: 100%;
  }

  .sponsored-icon {
    width: 16px;
    height: auto;
    margin: 2px 0 0 0;
    line-height: 0;
  }

}

// The text on its own
.sponsored-text {
  @include brand-font("500");
  font-size: 66%;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .1em;
  color: $vgc-white;
  @include background-gradient("90deg", $vgc-purple, $vgc-plum);
  padding: 4px;
}

// The icon on its own
.sponsored-icon {

}


.vgc-header {
  &__trending {

    .sponsored {
      margin-left: 0-$spacing_sm;
    }

    .sponsored-text {
      padding-left: $spacing_sm;
      @include small-text();
      line-height: 1.2;
      text-transform: none;

      a {
        color: $vgc-white;

        &:visited {
          color: $vgc-white;        
        }
        &:hover,
        &:visited:hover {
          color: $vgc-orange;
        }
        &:active {
          color: $vgc-white;
        }
      }

    }

  }
}