.vgc-event-highlights {
  display: flex;
  margin-bottom: $spacing_md;

  a {
    color: $vgc-white;
    font-weight: bold;
  }

  &__brand {
    display: flex;
    background-color: $vgc-orange;
    padding: 8px 16px;
    padding-right: 4px; // line up with everything else

    figure {
      margin: 0;
    }

    img {
      height: 24px;
      width: auto;
      display: block;
      margin: 0;
    }
  }

  &__content {

    font-size: 0.85em;

    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    //padding-left: $spacing_sm;
    @include background-gradient("45deg", $vgc-orange, $vgc-yellow);
    @include corner-bit("5px");

    ul {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-wrap: wrap;
      overflow: hidden;
      list-style-type: none;
      list-style-image: none;
      height: 40px;
      margin: 0;
      padding: 0;

      li {
        line-height: 38px; // Lato has a tall cap-height, so need to be weird here + padding below.
        padding: 0 $spacing_sm;

        a {
          padding: 0 5px 2px 5px;
          &:hover {
            background-color: rgba($vgc-white, .2);
          }
        }

        &::before {
          display: none;
        }
      }
    }


    &-link {
      font-family: Kelson, sans-serif;
      font-weight: 700;
      font-size: 85%;
      line-height: 1.3;
      text-transform: uppercase;
      display: none;
      line-height: 38px; // Lato has a tall cap-height, so need to be weird here.
      margin-left: auto;
      margin-right: $spacing_md;
    }

  }

}


/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {
  
  .vgc-event-highlights {
    &__content { 
      justify-content: space-between;     
      &-link {
        display: block;        
      }
    }
  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {
  
  .vgc-event-highlights {
    &__content { 
      font-size: 1em;
    }
  }

}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/


/*
.vgc-event-highlights--xxxxxxxxx {

  .vgc-event-highlights__brand {}
  .vgc-event-highlights__content {
    &-link {}
  }

}
*/

.vgc-event-highlights--e3-2019 {

  .vgc-event-highlights__brand {
    @include background-gradient("90deg", #e84132, #e5b80d);
  }

  .vgc-event-highlights__content {
    @include background-gradient("90deg", #04394d, #076f94);

    &-link {
      color: #e5b80d; 
    }

  }

}

.vgc-event-highlights--playstation {

  .vgc-event-highlights__brand {
    background-image: none;
    background-color: #0072ce;
  }

  .vgc-event-highlights__content {
    background-image: none;
    background-color: darken(#0072ce, 10%);

    &-link {
      color: $vgc-white; 
    }

  }

}


.vgc-event-highlights--xbox {

  .vgc-event-highlights__brand {
    background-image: none;
    background-color: #107c10;
  }

  .vgc-event-highlights__content {
    background-image: none;
    background-color: darken(#107c10, 10%);

    &-link {
      color: $vgc-white; 
    }

  }

}

.vgc-event-highlights--nintendo {

  .vgc-event-highlights__brand {
    background-image: none;
    background-color: #E60012;
  }

  .vgc-event-highlights__content {
    background-image: none;
    background-color: darken(#E60012, 10%);

    &-link {
      color: $vgc-white; 
    }

  }

}

.vgc-event-highlights--gamescom-2019 {

  .vgc-event-highlights__brand {
    background-image: none;
    background-color: #450095;    
  }

  .vgc-event-highlights__content {
    @include background-gradient("90deg", #450095, #00b9ff);

    &-link {
      color: #fff; 
    }

  }

}