/*************************************************************************************************/
/** Related Shortcode ****************************************************************************/
/*************************************************************************************************/

.vgc-related {

  background: #f5f5f5;
  padding: $spacing_sm;
  
  margin-top: 2rem;
  margin-bottom: 2rem;

  margin-left: -10px;
  margin-right: -10px;

  font-size: unset;
  line-height: unset;

  .vgc-related__title {
    font-size: 1rem !important;    
    padding-bottom: 6px !important;
    margin-bottom: 1rem !important;
    text-transform: uppercase;
    position: relative;      
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(90deg,#f47820,#feb823);
    }
  }

  .vgc-post {
    margin-bottom: 0 !important;
    .vgc-post__media {
      //display: none;    
      .bytes-image::after {
        border-right: 10px solid #f5f5f5 !important;
        border-bottom: 10px solid #f5f5f5 !important;
      }
    }    
    .vgc-post__info {
      //padding-top: 10px !important;
      h2 {
        font-size: 1.25rem !important;
        padding-bottom: 3px;
      }
      .strapline {
        display: none;
      }
    }
  }

}
.vgc-related + .vgc-related {
  border-top: 2px solid #fff;
  margin-top: -2rem;
  padding-top: 15px;
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {

  .vgc-related {
    .vgc-post {    
      .vgc-post__media {
        display: block;
      }
      .vgc-post__info {
        //padding-top: 10px !important;
        h2 {
          font-size: 1.5rem !important;
          padding-bottom: 0;
        }
        .strapline {
          display: block;
        }
      }
    }
  }

}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

  .vgc-related {    
    margin-left: 0;
    margin-right: 0;
    .vgc-post {
      .vgc-post__score {
        transform: translateX(-50%) translateY(-50%) scale(0.8);
      }
    }
  } 

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

  

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/