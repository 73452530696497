/*************************************************************************************************/
/** Lists ****************************************************************************************/
/*************************************************************************************************/

ul {
  padding-left: 1.25em;

  // http://bit.ly/2K4TwQz
  list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAJCAYAAAAPU20uAAAALUlEQVQoU2NkIAAYYfJfKhT+o6vl6XjACFaATRIkPtAKQPaD3AH3BS7fElQAAJmvGAqs5rNUAAAAAElFTkSuQmCC');

  ul {
    // http://bit.ly/2KbCC2W
    list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAJCAYAAAAPU20uAAAAOUlEQVQoU2NkIAAYYfJfKhT+o6vl6XjACFYAkwQJIGtAUYAsCdNEvgJkK1HcgOxImJVwR+HyLUEFAKvdKApo064nAAAAAElFTkSuQmCC');
  }
}

.post--featured {
  ul {
    // http://bit.ly/2K4TwQz
    list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAJCAYAAAAPU20uAAAALUlEQVQoU2NkIAAYYfKKMtH/0dXef7KUEawAmyRIfKAVgOwHuQPuC1y+JagAABgPGAoRH9wnAAAAAElFTkSuQmCC');

    ul {
      // http://bit.ly/2KbCC2W
      list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAJCAYAAAAPU20uAAAAOUlEQVQoU2NkIAAYYfKKMtH/0dXef7KUEawAJgkSQNaAogBZEqaJfAXIVqK4AdmRMCvhjsLlW4IKAF+dKAoFVrwJAAAAAElFTkSuQmCC');
    }
  }
}

.post--blog {
  ul {
    // http://bit.ly/2K4TwQz
    list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAJCAYAAAAPU20uAAAALUlEQVQoU2NkIAAYYfIKMV/+o6t9sISHEawAmyRIfKAVgOwHuQPuC1y+JagAAIrPGArUWThNAAAAAElFTkSuQmCC');

    ul {
      // http://bit.ly/2KbCC2W
      list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAJCAYAAAAPU20uAAAAOUlEQVQoU2NkIAAYYfIKMV/+o6t9sISHEawAJgkSQNaAogBZEqaJfAXIVqK4AdmRMCvhjsLlW4IKAKMdKAqlqBb1AAAAAElFTkSuQmCC');
    }
  }
}

.post--guide {
  ul {
    // http://bit.ly/2K4TwQz
    list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAJCAYAAAAPU20uAAAALUlEQVQoU2NkIAAYYfL/GRj+o6tlZGBgBCvAJgkSH2gFIPuh7sDvT7g3cSkDADJeDApFYvChAAAAAElFTkSuQmCC');

    ul {
      // http://bit.ly/2KbCC2W
      list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAJCAYAAAAPU20uAAAAOUlEQVQoU2NkIAAYYfL/GRj+o6tlZGBgBCuASYIEkDWgKECWhGkiXwGylShuQHYkzEq4o3D5lqACABMZFArZ2EmlAAAAAElFTkSuQmCC');
    }
  }
}

/*************************************************************************************************/
/**  Definition lists  ***************************************************************************/
/*************************************************************************************************/

dl {
  
  > div {
    display: flex;
  }

  dt {
    text-transform: uppercase;
    margin-right: $spacing_sm;
  }

  dd {
    margin: 0;
  }

}
