/*************************************************************************************************/
/** Tags *****************************************************************************************/
/*************************************************************************************************/

.vgc-tag {

  display: inline-block;
  position: relative;
  background: $black;
  color: $white;
  @include brand-font();
  @include smallest-text();
  text-transform: uppercase;
  padding: 5px 12px 5px 8px;  
  margin-right: 10px;
  margin-bottom: 10px;
  @include corner-bit("2px");

  svg {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 1em;
    height: 1em;    
    margin-right: .5em;

    path {
      fill: $vgc-white;
    }
  }

  &:hover,
  &:visited:hover {
    color: $vgc-white;
    text-decoration: none;
    background: none; // fixes sub-pixel black line
    @include background-gradient("45deg", $vgc-orange, $vgc-yellow);
  }

}

.post__tags {

  @include list-plain();

  margin-top: -0.5em;

//  background: rgba(#f0f, 0.02);

//  border-top: 1px solid $vgc-lightgrey;
//  padding-top: $spacing_sm - 10px;
//  margin-top: $spacing_sm;
//  margin-bottom: $spacing_sm;

  li {
    display: inline-block;

    > a {

      @extend .vgc-tag;    

      margin-top: 10px;
      margin-right: 10px;

      margin-bottom: 0;

    }
  }

}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) {

//  .post__tags {
//    padding-top: $spacing_md - 10px;
//    margin-top: $spacing_md;
//    margin-bottom: $spacing_md;
//  }

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) {

//  .post__tags {
//    padding-top: $spacing_lg - 10px;
//    margin-top: $spacing_lg;
//    margin-bottom: $spacing_lg;
//  }

}

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) {

//  .post__tags {
//    padding-top: $spacing_lg;
//    margin-top: $spacing_lg;
//    margin-bottom: $spacing_lg;
//  }

}

@include media-breakpoint-only(xl) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/