// Forms
form {
  label {
    @include brand-font();
    text-transform: uppercase;
    @include small-text();
  }

  input[type="text"],
  input[type="search"],
  input[type="number"],
  input[type="email"],
  textarea {
    border: 2px solid $vgc-grey;
    padding: ($spacing_sm / 2) $spacing_sm;
  }

  button {
    border: 0;
    padding: ($spacing_sm / 2) $spacing_lg;
    @include background-gradient();
    @include corner-bit("4px");

    img {
      width: 16px;
      height: 16px;
      display: block;
    }

    svg {
      width: 16px;
      height: 16px;
      fill: currentColor;
    }
  }
}

.vgc-comments__patreon-button {
  a {
    margin: 15px 0 0 0;
    max-width: 272px;
  }
}

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-only(sm) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(md) { 

}

@include media-breakpoint-only(md) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(lg) { }

@include media-breakpoint-only(lg) { }

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

@include media-breakpoint-up(xl) { }

@include media-breakpoint-only(xl) { }
